import { createSlice } from '@reduxjs/toolkit'



export const slice = createSlice({
    name: 'user',
    initialState:{
        user_id: '',
        lastName: '',
        firstName: '',
        location: '',
        telegramId: '',
        email: '',
        isAdmin: '',
        isAprover: false,
        isAuthenticated: false,
        token: '',
        IsTutorial: true,
        Contrato: null,

    },
    reducers:{
        changeAuth(state,{payload}){
            return {...state, isAuthenticated: payload.token !== "" ? true: false, isAdmin: payload.is_admin,  firstName: payload.first_name , lastName: payload.last_name, token: payload.access, location: payload.location, user_id: payload.user_id, isAprover:payload.is_aprover, email: payload?.email, telegramId: payload?.telegram_id, IsTutorial: true, Contrato: payload.contrato}
        },
        logout(state){
            return {...state, firstName: '', lastName: '', location:'', isAdmin: '', token: '', isAuthenticated: false, user_id: '', isAprover: false, email: '', Contrato: null}
        },
        changeTutorial(state){
            return{...state, IsTutorial: false}
        },
    }
})

export const{changeAuth, logout, changeTutorial} = slice.actions

export const selectUser = state => state.user 

export default slice.reducer