import './styles.css'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import ptBrLocale  from '@fullcalendar/core/locales/pt-br';


const events = [
    { title: 'Meeting', start: new Date() }
]

const CalendarVisitors = () => {
  
function renderEventContent(eventInfo) {
    return (
        <div>
        <b>{eventInfo.event.title}</b>
        
        </div>
    )
    }

    

  return(
    <div className="my-custom-calendar">
      <FullCalendar
        plugins={[dayGridPlugin]}
        events={[
            { title: 'Reunião', date: '2023-06-17'},
            { title: 'Reunião', date: '2023-06-17'},
            { title: 'Teste', date: '2023-06-17'},
            { title: 'Visita Tecnica', date: '2023-06-18'}
        ]}
        headerToolbar={{
            left:'',
            center: 'title',
            right: 'prev,next today',
        }}
        locale={ptBrLocale}
        initialView="dayGridMonth"
        editable
        selectable
        selectMirror
        dayMaxEvents
        nextDayThreshold={'09:00:00'}
        select={()=>console.log("w")}
        height={600}
        eventContent={renderEventContent} 
        style={{overflowY: 'hidden', color: 'gray',}}
      />
      
    </div>
  );
};

export default CalendarVisitors;