import React, { useContext } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  Outlet
} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-notifications/lib/notifications.css';
import LoginPage from './screens/Login';
import Layout from './components/Layout';
import HomePage from './screens/Home';
import AdminScreen from './screens/Admin';
import SociticaoScreen from './screens/Solicitacao';
import ProfileScreen from './screens/Profile';
import ReelFrota from './screens/RelFrota';
import { useSelector } from "react-redux";
import ScreeRelSolicitacao from './screens/RelSolicitacao';
import VisitorsScreen from './screens/Visitors';
import RoadMapScreen from './screens/RoadMap';
import { SolicitacaoProvider } from './context/Context';

moment().locale('pt-br');







const ProtectedRoutes = () => {
  const state = useSelector(state=> state)

  if (!state.auth.isAuthenticated) {
    
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};



const AdminRoute = ({children}) =>{
  const state = useSelector(state=> state)
  if (!state.auth.isAdmin){
    alert("usuario sem altorização")
    return <Navigate to="/home" replace />
  }
  return children;
}


function App() {
  return (
    <React.Fragment>
      <SolicitacaoProvider>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route
            path="/"
            element={
              <Layout>
                <ProtectedRoutes/>
              </Layout>
            }
          >
            <Route path="/home" element={<HomePage />} />
            <Route path="/solicitacao" element={<SociticaoScreen />} />
            <Route path='/agenda' element={<VisitorsScreen/>}/>
            <Route path="/admin" element={<AdminRoute><AdminScreen/></AdminRoute>}/>
            <Route path="/relatorios/frota" element={<ReelFrota/>}/>
            <Route path="/relatorios/solicitacao" element={<ScreeRelSolicitacao/>}/>
            <Route path='/perfil' element={<ProfileScreen/>}/>
          </Route>
          <Route path='/roadmap' element={<RoadMapScreen/>}/>
        </Routes>
      </SolicitacaoProvider>  
    </React.Fragment>
  )  
}

export default App;
