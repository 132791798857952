import { Icon, Button, Tab} from 'semantic-ui-react'
import { useEffect, useState} from 'react';
import CadastroFuncionario from '../../components/FuncionariosControl';
import CadastroFrota from '../../components/FrotaControl';
import AcessosMap from '../../components/AcessosControl';
import './styles.css';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../api/api';
import axios from 'axios';




const AdminScreen = () =>{
    const state = useSelector(state=> state.auth)


      const panes = [
        {
          menuItem: { key: 'users', icon: 'users', content: 'Funcionarios' },
          render: () => 
          <Tab.Pane attached={false}>
            <CadastroFuncionario/>
          </Tab.Pane>,
        },
        {
          menuItem: { key: 'veiculos', icon: 'car', content: 'Frota' },
          render: () => <Tab.Pane attached={false}>
            <CadastroFrota/>
          </Tab.Pane>,
        },
        {
          menuItem: { key: 'acessos', icon: 'globe', content: 'Acessos' },
          render: () => <Tab.Pane attached={false}>
            <AcessosMap/>
          </Tab.Pane>,
        },
    ]  


    return(
        <div className='container-admin'>
            <Tab menu={{ secondary: true, pointing: true }} panes={panes}/>
        </div>
    )
}
export default AdminScreen