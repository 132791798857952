import './styles.css';
import { DatePicker,SelectPicker, InputPicker, Input } from 'rsuite';
import { useState, useEffect } from 'react';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import BarChartItem from '../../components/BarChart';
import RoscChard from '../../components/RoscChard';
import TableSo from '../../components/Table';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../api/api';
import { animated, useSpring } from '@react-spring/web'
import introJs from 'intro.js';
import 'intro.js/introjs.css';



const data = ['Diario', 'Semanal', 'Mensal'].map(
    item => ({ label: item, value: item })
  );


const datas = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
  );

const ScreeRelSolicitacao = () =>{
    const state = useSelector(state=>state.auth)
    const[isSearchVisible, setIsSearchVisible] = useState(false);
    const[details, setDetails] = useState({
        negado: 0,
        concluido: 0,
        pendentes: 0
    })
    const[contratos, setContratos] = useState([]);
    const[aprovacao, setAprovacao] = useState([]);
    const[search, setSearch] = useState(null);
    const[rvalue, setRvalue] = useState(null); 
    const[paramsv, setParams] = useState(null)

    
    
    useEffect(() => {
        function handleScroll() {
            const filterContainer = document.querySelector('.container-bory');
            if (!filterContainer) return;
    
            const rect = filterContainer.getBoundingClientRect();
            const isAtTableTop = rect.bottom <= 800;
            setIsSearchVisible(isAtTableTop);
        }
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const props = useSpring({
        to: details,
        from: { negado: 0, concluido: 0, pendentes: 0 },
        config: { duration: 900 }
    });





    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}aprovacoes/relatorios/`, {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${state.token}`
                    },
                    params:{
                        data:paramsv
                    }
                });
                const newData = response?.data?.reduce((acc, item) => {
                    if (item?.status === 'concluida') {
                        acc.concluido += item?.total || 0;
                    } else if (item?.status === 'cancelada' || item?.status === 'cancelar') {
                        acc.cancelada += item?.total || 0;
                    } else if (item?.status === 'negada' || item?.status === 'negado') {
                        acc.negado += item?.total || 0;
                    }
                    return acc;
                }, {
                    negado: 0,
                    concluido: 0,
                    cancelada: 0
                });

                setDetails(newData);
                
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [paramsv]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}aprovacoes/relatorios/contratos`, {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${state.token}`
                    },
                });
                setContratos(response?.data?.contratos)   
                setAprovacao(response.data.aprovadores)         
            } catch (error) {
                console.error('Error:', error);
            }
        };
    
        fetchData();
    }, []);



    
    const childChange = (childValue) =>{
        setSearch(childValue)
        setParams(childValue)
    }


    const activeRosc = (roscValue) =>{
        console.log(roscValue)
        setRvalue(roscValue)
    }




    return(
        <div className='container-root'>
            <div className='container-filter'>
                <div className='input' style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                <Input 
                    block
                    placeholder='Pesquisar' 
                    style={{maxWidth:250, fontSize: 11}}
                    value={search}
                    onChange={(text)=>setSearch(text.toUpperCase())}
                />
                </div>
            </div>
            <div className='main-cards'>
                <div className='card-main concluidas'>
                    <CheckOutlineIcon style={{fontSize: '5em', color: '#e7e7e7'}}/>
                    <div>
                        <span>Concluidas</span>
                        <animated.h1 style={{fontSize: '3em'}}>{props.concluido?.interpolate(val => Math.floor(val))}</animated.h1>
                        
                    </div>

                </div>

                <div className='card-main pendente'>
                    <InfoOutlineIcon style={{fontSize: '5em', color: '#e7e7e7'}}/>
                    <div>
                        <span>Canceladas</span>
                        <animated.h1 style={{fontSize: '3em'}}>{props.cancelada?.interpolate(val => Math.floor(val))}</animated.h1>
                    </div>
                </div>

                <div className='card-main cancel'>
                    <CloseOutlineIcon style={{fontSize: '5em', color: '#e7e7e7'}}/>
                    <div>
                        <span>Negadas</span>
                        <animated.h1 style={{fontSize: '3em'}}>{props.negado?.interpolate(val => Math.floor(val))}</animated.h1>       
                    </div>
                </div>
            </div>
            <div className='container-bory' id="container-bory">
                <div className='container-chart'>
                    <div className='step-01'>
                        <span style={{margin: 3, fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px',letterSpacing: -1, fontSize: 13}}>Análise das Solicitações por Centro de Custo</span>
                        <BarChartItem data={contratos} childChange={childChange} rvalue={rvalue}/>
                    </div>
                    <div className='step-02'>
                        <span style={{margin: 3, fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px',letterSpacing: -1, fontSize: 13}}>Distribuição de Aprovações por Aprovador</span>
                        <RoscChard data={aprovacao} childChange={childChange} activeRosc={activeRosc}/>
                    </div>
                </div>
                <div className='table'>
                    <span style={{margin: 3, fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px',letterSpacing: -1, fontSize: 13}}>Tabela de Solicitações</span>
                    <TableSo values={search}/>
                </div>
                
            </div>
        </div>
    )
}
export default ScreeRelSolicitacao