import { createSlice } from '@reduxjs/toolkit'



export const slice = createSlice({
    name: 'vehicles',
    initialState:{
        data: []
    },
    reducers:{
        changeVehicles(state,{payload}){
            return {...state, data: payload}
        },
        updateVehicles(state, {payload}) {
            payload.forEach(newData => {
                const {id, tag, placa, last_modified, autorizacao} = newData;

               
                const index = state.data.findIndex(vehicles => vehicles.id === id);

                if (index !== -1) {
                   
                    state.data[index] = {...state.data[index], tag, placa, last_modified, autorizacao};
                } else {
                   
                    state.data.push(newData);
                }
            })
        },
        clearVehicles(state){
            return {...state, data: ''}
        }
    }
})

export const{changeVehicles, clearVehicles, updateVehicles} = slice.actions

export const selectUser = state => state.vehicles

export default slice.reducer