import {Icon, Image} from 'semantic-ui-react'
import { useEffect, useState, useRef} from 'react'
import img from '../../assets/cart.png'
import recordsCard from '../../assets/record.json'
import './styles.css'
import moment from 'moment'
import { useSelector } from 'react-redux';
import {Placeholder, Panel, Button} from 'rsuite';
import esc from '../../assets/escavador.png';
import { motion, AnimatePresence } from 'framer-motion';
import Lottie from 'react-lottie-player';




const FeedComponent = ({record, active}) =>{
  const state = useSelector(state=>state.auth);
  const[selectedId, setSelectedId] = useState(null);
  const [selectedDivTop, setSelectedDivTop] = useState(0);










  const handleOpenAnimated = (id, event) => {
    setSelectedId(id);
    const parentMotionDiv = event.target.closest(".motion-div-item");
    if (parentMotionDiv) {
      let adjustment = 100;
  
      
      const windowHeight = window.innerHeight;
  
      
      const itemBottomPosition = parentMotionDiv.getBoundingClientRect().bottom;
  
     
      if (windowHeight - itemBottomPosition < 250) {
        adjustment = 200; 
      }
  
      const positionTop = parentMotionDiv.getBoundingClientRect().top + window.scrollY - adjustment;
      setSelectedDivTop(positionTop);
    }
  }
  

  const handleCloseAnimated = () => {
    setSelectedId(null);
    setSelectedDivTop(0);
  }


    return(
      <div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', width: '100%', position: 'relative', overflow: 'hidden', minHeight: '100vh'}}>
      
        {record?.map(item => (
          <motion.div
            key={item.id}
            layoutId={item.id}
            className="motion-div-item"
            style={{
              marginBottom: '10px',
              width: '100%',
              borderRadius: 10,
            }}
          > 
          <div style={{ minHeight: 100, borderRadius: 10, padding: 5, margin: 0, border: '1px solid #f5f5f5bc', backgroundColor: '#f5f5f5bc'}}>
            {active ?(
              <Placeholder.Paragraph rows={4} graph="circle" active />
            ):(
              <div style={{ height: '100%', borderRadius: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                  <img alt='car' src={img} style={{height: 50, width: 50, margin: 3}}/>
                  <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                    {item?.condutor?.nome === undefined ? (
                      <a style={{ fontSize: '0.8em', fontFamily: 'Open Sans, sans-serif', fontWeight: 600, cursor: 'pointer', color: 'gray' }}>
                      {item?.condutor_terceiro?.toUpperCase()}
                      </a>
                    ):(
                    <Button appearance='link' style={{ fontSize: '0.8em', fontFamily: 'Open Sans, sans-serif', marginBottom: -10, marginLeft: -10}} >
                      {item?.condutor?.nome &&
                    `${item?.condutor?.nome.split(' ')[0]} ${item.condutor?.nome.split(' ')[item?.condutor?.nome.split(' ').length - 1]}`}
                    </Button>
                    )}
                    <div style={{fontSize: '0.8em'}}>
                      <Icon  name='car'style={{color: 'gray', fontSize: '0.8em'}}/>{item?.veiculo?.tag === undefined ? 'VEICULO TERCEIRO': item?.veiculo?.tag}
                    </div>
                    
                    <div style={{fontSize: '0.8em'}}>
                      <Icon name='question circle outline' style={{color: 'gray', fontSize: '0.8em'}}/>{item?.motivo?.toUpperCase()}
                    </div>

                    <div style={{fontSize: '0.8em'}}>
                        {item?.veiculo?.placa === null || item?.veiculo?.placa?.length === 0 ?(
                        <>
                        <Icon name='tag'  style={{color: 'gray'}}/><span>sem placa</span>
                        </>
                        ): item?.veiculo?.placa === undefined ?(
                        <>
                        <Icon name='tag' style={{color: 'gray', fontSize: '0.8em'}}/>{item?.veiculo_terceiro}
                        </>
                        ):(
                        <>
                        <Icon name='tag' style={{color: 'gray', fontSize: '0.8em'}}/>{item?.veiculo?.placa}
                        </>
                        )}
                    </div>
                    {(item?.destino?.includes(state?.location))||(item?.destino?.includes(state?.location)) ?(
                        <div style={{fontSize: '0.8em'}}>
                            <Icon name='road'  color='green' style={{fontSize: '0.8em'}}/>{item?.destino?.toUpperCase()}
                        </div>
                        ): (
                        <div style={{fontSize: '0.8em'}}>
                            <Icon name='road'  color='red' style={{fontSize: '0.8em'}}/>{item?.destino?.toUpperCase()}
                        </div>
                    )}
                    <div style={{fontSize: '0.8em', minWidth: '20%'}}>
                      <Icon name='tachometer alternate' style={{color: 'gray', fontSize: '0.8em'}}/>
                      <span style={{color: 'grey', fontSize: 11,}}>{item?.km_hr}{item?.km_hr_resto != null? ' / ':null}
                      
                      <span style={{color: 'red', fontSize: '0.8em'}}>{item?.km_hr_resto != null ? `${item?.km_hr_resto}`:null}</span>
                    
                      </span>
                      
                      <span style={{fontSize: '0.8em', padding: 5}}>{item?.data_resto?.split('.')[0]}</span>
                      
                    </div>

                    {item?.equipamentos && Array.isArray(item.equipamentos) && item.equipamentos.length > 0 ? (
                        <div style={{ margin: '1px', display: 'flex', flexDirection: 'column', height: '50%', maxWidth: 200}}>
                          <div style={{margin: 1}}>
                          <span style={{ fontFamily: 'Open Sans, sans-serif', fontWeight: 600, fontSize: '0.8em'}}>Equipamentos</span>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                          {item?.equipamentos.map(value=>(
                          <div style={{marginLeft: '2%'}}>
                              <Image src={esc} size='mini'/>
                              <Icon name='tag' style={{color: 'gray'}}/><span style={{color: 'gray', fontSize: '0.8em'}}>{value?.toUpperCase()}</span>
                          </div>
                          ))}
                        </div>
                    </div>  
                    ):null}


                  </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '50%', alignItems: 'flex-end'}}> 
                  <span style={{fontFamily:'Arial', fontSize: '0.8em', color: 'gray', top: '0px',}}>
                    {(moment(item?.date).year() < 2023 || (moment(item?.date).year() === 2023 && moment(item?.date).month() < 2)) ? moment.utc(item?.date).format('lll') : moment(item?.date).format('lll')}
                  </span>
                </div>
              </div>
            )}
          </div>
          </motion.div>
        ))}
      
      <AnimatePresence>
        {selectedId !== null && (
          <motion.div
            key={selectedId}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: 'absolute',
              top: `${selectedDivTop}px`,
              left: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              color: 'white',
              zIndex: 1,
            }}
            
          >
            <Panel header="Panel title" style={{backgroundColor: '#fff', width: '90%', minHeight: 300, borderRadius: 10}} shaded>
            <Lottie
              loop={false}
              animationData={recordsCard}
              play={true}
              style={{height: '10%', width: '10%'}}
            />
            {/* <TimeLineComponent data={[]}/> */}
            </Panel>
          </motion.div>
        )}
      </AnimatePresence>
      </div>
    </div>
        
        

    )
}
export default FeedComponent;
