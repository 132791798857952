
import React, { createContext, useState } from 'react';


const SolContext = createContext()



const SolicitacaoProvider = ({children}) =>{
  const[solicitacoes, setSolicitacoes] = useState({
    'aprovada': null,
    'pendente': null
  });
  const[solicitacaoData, setSolicitacaoData] = useState([])
  const[loader, setLoader] = useState(false);


  return(
    <SolContext.Provider value={{solicitacoes, setSolicitacoes, solicitacaoData, setSolicitacaoData}}>
      {children}
    </SolContext.Provider>
  )
}
export { SolContext, SolicitacaoProvider };
