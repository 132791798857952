
import Veiculospage from '../../components/veiculos'
import Funcionariospage from '../../components/funcionarios'
import './styles.css'
import LeaderBoardUsuarios from '../../components/leaderUsuarios'
import { useEffect, useState} from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux'
import { BASE_URL } from '../../api/api';
import FeedComponent from '../../components/Feed';
import { IconButton, InputPicker, Modal, Button, Input, DatePicker} from 'rsuite';
import SettingHorizontalIcon from '@rsuite/icons/SettingHorizontal';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';



function Homepage(){
    const state = useSelector(state=> state.auth)
    const[data,setData] = useState([]);
    const[record, setRecords] = useState([1, 2, 3, 4, 5, 7])
    const[search, setSearch] = useState('')
    const[result, setResult] = useState([])
    const[loading, setLoading] = useState(false);
    const[active, setActive] = useState(false);
    const[open, setOpen] = useState(false);
    const[searchTerc, setSearchTerc] = useState(null);
    const[data_inicial, setDataIni] = useState(null);
    const[data_final, setDataFim] = useState(null);
    const[dataTime, setDataTime] = useState(new Date());
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    



    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${BASE_URL}api/relatorios/`, {
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              'Authorization': `Bearer ${state.token}`
            },
          });
  
          setData(response?.data)
        } catch (error) {
          console.error('Error:', error);
        }
      };
  
      fetchData();
    }, [state.token]);





    useEffect(() => {
      setLoading(true)
      if(search.length > 2){
          async function fetchEmployees() {
              try {
              const response = await axios.get(`${BASE_URL}api/veiculos/`, {
                  headers: {
                      'Content-type': 'application/json; charset=UTF-8',
                      'Authorization': `Bearer ${state.token}`
                  },
                  params: { q: search }
              });
              setLoading(false)
              setResult(response.data?.results)
              } catch (error) { 
              
              }
          }
      
          fetchEmployees();
      }
    }, [search.length]);





    useEffect(()=>{
      setActive(true)
      const fetchAll = async () => {
        try {
          const response = await axios.get(`${BASE_URL}api/searchrecord/`, {
              headers:{
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': `Bearer ${state.token}`
              },
              params: {
                'search': 'all',
            },
          });
          setActive(false)
          setRecords(response.data)
        } catch (error) {
          console.error("Houve um erro ao buscar os dados:", error);
        }
      }
      fetchAll()
    },[])


 


    const fetchDataTime = async () => {
      setActive(true)
      setOpen(false)
      try {
        const response = await axios.get(`${BASE_URL}api/searchrecord/`, {
            headers:{
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
            },
            params: {
              'veiculo': searchTerc,
              'data_inicial': data_inicial,
              'data_final': data_final
          },
        });
        setRecords(response.data)
        setActive(false)
      } catch (error) {
        console.error("Houve um erro ao buscar os dados:", error);
      }
    }

    const fetchData = async (veiculo) => {
      setActive(true)
      try {
          const response = await axios.get(`${BASE_URL}api/searchrecord/`, {
              headers:{
                  'Content-type': 'application/json; charset=UTF-8',
                  'Authorization': `Bearer ${state.token}`
              },
              params:{
                'search': veiculo
              }
          });
          setRecords(response.data)
          setActive(false)
      } catch (error) {
        console.error("Houve um erro ao buscar os dados:", error);
      }
  }

  
    const searchData = async (value) =>{
      setActive(true)
      
      const response = await axios.get(`${BASE_URL}api/searchrecord/`, {
        headers:{
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${state.token}`
        },
        params:{
          'data': value
        }
    });
    setRecords(response.data)
    setActive(false)
    }

    return(    
      <div className='container-home'>
        <Modal open={open} onClose={()=>setOpen(false)}>
          <Modal.Header>
            <Modal.Title>Pesquisa Avançada</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
            <label>Pesquisar:</label>
            <Input
              style={{ width: '100%'}} 
              onChange={(text)=>setSearchTerc(text.toLocaleUpperCase())}
              placeholder='Condutor ou Veiculo'
              
              
            />


            <label>Data de:</label>
            <Input  placeholder='data'
              type='date' 
              style={{width: '100%'}}
              onChange={(text)=> setDataIni(text.toLocaleUpperCase())}
              
              />

              <label>Data ate:</label>
              <Input  placeholder='data'
              type='date' 
              style={{width: '100%'}}
              
              onChange={(text)=> setDataFim(text.toLocaleUpperCase())}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={fetchDataTime} appearance="primary">
              Ok
            </Button>
            <Button onClick={()=>setOpen(false)} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', padding: 5}}>
          <div style={{minHeight: 40, width: '100%', position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#fff', marginBottom: 5, borderRadius: 5, border: '1px solid #c7c6c6', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 5}}>
          
           <InputPicker
              style={{ width: 224 }} 
              data={Array.isArray(result) ? result.map(item => ({ label: item?.tag, value: item?.tag })) : []} 
              onSearch={(text)=>setSearch(text.toLocaleUpperCase())} 
              onChange={(e)=>fetchData(e)}
              placeholder='Pesquisar'
              onClean={()=>setActive(false)}
              renderMenu={menu => {
                if (loading) {
                  return (
                    <p style={{ padding: 10, color: '#999', textAlign: 'center' }}>
                      <SpinnerIcon spin /> Loading...
                    </p>
                  );
                }
                return menu;
              }}
            />
            <div style={{display: 'flex', flexDirection: 'row'}}>
              {!isMobile &&(
                <DatePicker 
                appearance="subtle" 
                style={{ width: 200, marginRight: 5 }}
                value={dataTime}
                onChange={(date) => {
                  const year = date.getFullYear();
                  const month = String(date.getMonth() + 1).padStart(2, '0');
                  const day = String(date.getDate()).padStart(2, '0');
                  const formattedDate = `${year}-${month}-${day}`;
                  searchData(formattedDate);
                  setDataTime(date)
                }}
                />
              )}
              
              <IconButton appearance='primary' style={{backgroundColor: '#008055'}} icon={<SettingHorizontalIcon />} onClick={()=>setOpen(true)}/>
            </div>
            
          </div>
          <div style={{border: '1px solid #c7c6c6', borderRadius: 5, padding: 10}}>
          <FeedComponent record={record} active={active}/>
          </div>
        </div>
        
        <div className='child-right'>       
            <Funcionariospage data={data?.registros_func}/> 
            <Veiculospage data={data?.registros_veic}/>   
            <LeaderBoardUsuarios data={data?.destinos_mais_rodados}/>            
            <div className='step-05'>
              
            </div>
        </div>
      </div>


 
    )


    

  


}

export default Homepage



