import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {store, persistor} from './store/store';
import { PersistGate } from "redux-persist/integration/react";


if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {  
    navigator.serviceWorker.register('/sw/sw.js', {scope: '/sw/'})
        .then(() => {
            console.log('Service Worker registrado com sucesso');
        })
        .catch(error => {
            console.log('Falha ao registrar o Service Worker:', error);
        });
  });
}

console.log = function() {};
console.warn = function() {};
console.error = function() {};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <Provider store={store}>
        <PersistGate persistor={persistor}>
        <BrowserRouter>
        <App />
        </BrowserRouter>
        </PersistGate>
        </Provider>
    
);

reportWebVitals();
