import './styles.css';
import { useState, useEffect } from 'react';
import { Sidenav, Nav } from 'rsuite';
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import PageIcon from '@rsuite/icons/Page';
import BranchIcon from '@rsuite/icons/Branch';
import TaskIcon from '@rsuite/icons/Task';
import { useNavigate } from 'react-router-dom';
import TagAuthorizeIcon from '@rsuite/icons/TagAuthorize';
import TrendIcon from '@rsuite/icons/Trend';
import TagDateIcon from '@rsuite/icons/TagDate';
import { useLocation } from 'react-router-dom';




const CustomSidenav = ({ openKeys, expanded, onOpenChange, onExpand, ...navProps }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [activeKey, setActiveKey] = useState(null);
  
  

  useEffect(() => {
    setActiveKey(location?.pathname)
  }, [location?.pathname]);


  useEffect(() => {
    localStorage.setItem('activeKey', activeKey);
  }, [activeKey]);

  const headerStyles = {
    paddingLeft: 10,
    backgroundColor: '#2c2c2c',
    color: ' #fff'
  };

  const navItemStyles = {
    color: 'white',
    fontSize: 14
  };

  const activeNavItemStyles = {
    color: 'blue',
    backgroundColor: '#ebebeb',
    fontSize: 14
  };

  

  

  return (
    <div>
      
      <Sidenav
        appearance="subtle"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        className='sider-nav'
        expanded={expanded}

      >
        <Sidenav.Header>
          <div style={headerStyles}><span style={{ marginLeft: 10, fontSize: 17 }}>PMonitor</span></div>
        </Sidenav.Header>
        <Sidenav.Body>
          <Nav {...navProps}>
            <Nav.Item
              eventKey="1"
              icon={<PageIcon />}
              active={true}
              onClick={()=>navigate('/home')}
              style={activeKey === '/home' ? activeNavItemStyles : navItemStyles}
            >
              Controle
            </Nav.Item>
            <Nav.Item
              eventKey="2"
              icon={<TaskIcon />}
              style={activeKey === '/solicitacao' ? activeNavItemStyles : navItemStyles}
              onClick={()=>navigate('/solicitacao')}
            >
              Solicitação de saida
            </Nav.Item>

            

            <Nav.Menu title={<span style={navItemStyles} className='relatorio'>Relatórios</span>} icon={<TrendIcon style={{color: '#fff'}}/>}>
            <Nav.Item  style={activeKey === '/relatorios/solicitacao' ? activeNavItemStyles : navItemStyles} className='relatorio-step-01'  onClick={()=>navigate('/relatorios/solicitacao')}>Solicitações</Nav.Item>
            {/* <Nav.Item eventKey="4-2" style={activeKey === '4-2' ? activeNavItemStyles : navItemStyles} onClick={()=>navigate('/relatorios/frota')}>Frota</Nav.Item> */}
            </Nav.Menu>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  );
};

const SidebarComponent = (props) => {
  const [activeKey, setActiveKey] = useState(localStorage.getItem('activeKey') || '1');

  

  return (
    <CustomSidenav
      activeKey={activeKey}
      onSelect={setActiveKey}
    />

  )
}
export default SidebarComponent
