import {Form, Button} from 'rsuite'
import './styles.css'
import {useState,} from 'react'
import { BASE_URL } from '../../api/api';
import axios from 'axios';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeAuth } from '../../store/authSlice';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';

function LoginPage(){
    const dispatch = useDispatch()
    const[username, setUsername] = useState(null);
    const[userpass, setUserpass] = useState(null);
    const[email, setEmail] = useState(null);
    const[open, setOpen] = useState(false);
    const navigation = useNavigate();
    const[loginprogress, setLoginProgress] = useState(false)


 

    async function Loggin() {
        if(username === '' || userpass === '') {
            NotificationManager.warning('Atenção', 'Proibido campos em banco');  
            return;
        }
            
        setLoginProgress(true);
        const response = await axios({
            url: `${BASE_URL}api/auth/`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data:{
                username: username,
                password: userpass
            }
        }).then(function(response){{
            dispatch(changeAuth(response.data));  
            navigation('/home');
            setLoginProgress(false);
            
        }}).catch(function(error){
            if(error.response.status === 401){
                NotificationManager.error(`Usuario ou senha invalido`, 'Error');
                setLoginProgress(false); 
                return;
            }
            NotificationManager.error(`${error.message}`, 'Error');
            setLoginProgress(false);
          
        })
            
    
        
    }
    



    const ResetPassword = () =>{
        axios({
            url: `${BASE_URL}api/password_reset/`,
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data:{
                email: email
            }
            
        })
        .then(function(response){
            NotificationManager.success(`Foi enviado um email para ${email}`, 'Sucesso');
        })
        .catch(function(error){
            NotificationManager.error(`${error.message}`, 'Error');
        })
    }



    return(      
        <div className='container-login'>
            <NotificationContainer/>
            <div className='child-one'>
                <div>

                </div>

                <div className='child-one-first'>
                    {open?(
                     <>
                        <div className='child-tow'>
                        <Button style={{fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px', marginLeft: -20}} appearance="link" onClick={()=>setOpen(false)}><ArrowLeftLineIcon/>Login</Button>
                        </div>
                        <div className='child-tree'>
                        <Form fluid style={{marginTop: '10%'}}>
                            <Form.Group>
                            <Form.ControlLabel>Email</Form.ControlLabel>
                            <Form.Control name="Email" onChange={(e)=> setEmail(e)} autoComplete='off' />
                            </Form.Group>
                            <Form.Group>
                            <Button appearance="primary"  onClick={ResetPassword} style={{width: '100%', backgroundColor: 'green', marginTop: '10%'}}>Enviar</Button>
                            </Form.Group>
                        </Form>
                        </div>

                     </>   
                    ):(
                        <>
                        <div className='child-tow'>
                        <h2 style={{fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px'}}>Login</h2>
                        <p style={{fontFamily: 'Open Sans, sans-serif', fontSize: '1rem', marginTop: '-0.5rem'}}>pmonitor</p>
                        </div>
                        <div className='child-tree'>
                        <Form fluid>
                            <Form.Group>
                            <Form.ControlLabel>Username</Form.ControlLabel>
                            <Form.Control name="username" onChange={(e)=> setUsername(e)} />
                            </Form.Group>
                            <Form.Group>
                            <Form.ControlLabel>Senha</Form.ControlLabel>
                            <Form.Control name="senha" type="password" autoComplete="off" onChange={(e)=> setUserpass(e)} />
                            </Form.Group>
                            <Form.Group style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            {/* <Button appearance="link" onClick={()=>setOpen(true)}>Esqueceu sua senha?</Button> */}
                            </Form.Group>
                            <Form.Group>
                            <Button appearance="primary" loading={loginprogress} onClick={()=> Loggin()} style={{width: '100%', backgroundColor: 'green', marginTop: '10%'}}>Entrar</Button>
                            </Form.Group>
                        </Form>
                        </div>
                        </>
                    )}
                    
                        


                    
                </div>
            </div>
        </div>
        
       
    )
}
export default LoginPage