import {  List,  Button, Icon,} from 'semantic-ui-react'
import { useEffect, useState, } from 'react';
import 'semantic-ui-css/semantic.min.css'
import './styles.css'
import 'rsuite/dist/rsuite.min.css';
import LetteredAvatar from 'react-lettered-avatar';
import MenuIcon from '@rsuite/icons/Menu';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import { useSelector } from 'react-redux';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { BASE_URL } from '../../api/api';
import axios from 'axios';
import { Drawer, IconButton, Placeholder, Dropdown, Input, Form, Checkbox, Loader, CheckboxGroup } from 'rsuite';




const renderIconButton = (props, ref) => {
    return (
      <IconButton {...props} size="sm" ref={ref}  icon={<MenuIcon/>} style={{backgroundColor: '#dfdede'}}/>
    );
};

function AcessosMap(){
    const state = useSelector(state=> state.auth)
    const[funcionario, setFuncionario] = useState([]);

    const[open, setOpen] = useState(false);
    const[openEditor, setOpenEditor] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const[loader, setLoader] = useState(false);
    const[names, setNames] = useState({
        'id': '',
        'first_name': '',
        'last_name': '',
        'username': '',
        'email': '',
        'password': '',
        'is_admin': false,
        'fone': '',
        'autorizacao': true,
    })


    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${BASE_URL}api/usuarios/`, {
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
              }
            });
            setFuncionario(response.data);
          } catch (error) {
            console.error('Error:', error);
          }
        };
    
        fetchData();
      }, []);



    
    //   useEffect(() => {
    //     if(searchQuery.length > 3){
    //         async function fetchEmployees() {
    //             try {
    //             const response = await axios.get(`${BASE_URL}api/veiculos/`, {
    //                 headers: {
    //                     'Content-type': 'application/json; charset=UTF-8',
    //                     'Authorization': `Bearer ${state.token}`
    //                 },
    //                 params: { q: searchQuery }
    //             });
    //             setFuncionario(response.data);
    //             } catch (error) {
                
    //             }
    //         }
        
    //         fetchEmployees();
    //     }
    // }, [searchQuery]);  

    
    



    const HandleChange = (name, value) =>{
        setNames(prevValues => ({
            ...prevValues,
            [name]: value.toUpperCase()
        }));
    }


    const handleCheckboxChange = (value) => {
        setNames((prevState) => ({
          ...prevState,
          autorizacao: names.autorizacao === true ? false:true,
        }));
    };
    

    const HandleSubmit = () =>{
        setLoader(true)
        axios({
            url: `${BASE_URL}api/veiculos/`,
            method: 'POST',
            headers:{
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
            },
            data:names
        }).then(function (response){
            setLoader(false)
            NotificationManager.success('Funcionarios inserido', 'Sucesso');
            setNames(prevValues => ({
                ...prevValues,
                tag: '',
                placa: '',
                autorizacao: true
            }));
            
        }).catch(function (error){
            NotificationManager.error(`${error?.message}`, 'Warning');
            setLoader(false)
        })
    }


    const HandleUpdade = () =>{
        setLoader(true)
        axios({
            url: `${BASE_URL}api/veiculos/${names.id}/`,
            method: 'PUT',
            headers:{
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
            },
            data:names
        }).then(function (response){
            setLoader(false)
            NotificationManager.success('Veiculo atualizado', 'Sucesso');
            setNames(prevValues => ({
                ...prevValues,
                id:'',
                tag: '',
                placa: '',
                autorizacao: true
            }));
            setOpenEditor(false)

            // const updatedVehicleIndex = veiculos.findIndex(veiculo => veiculo.id === response.data.id);
            // if (updatedVehicleIndex !== -1) {
            //     const updatedVeiculos = [...veiculos];
            //     updatedVeiculos[updatedVehicleIndex] = response.data;
            //     setVeiculos(updatedVeiculos);
            // }
            
        }).catch(function (error){
            NotificationManager.error(`${error?.message}`, 'Warning');
            setLoader(false)
        })
        

    }
    
    
    




    

    return(
        <>
        <div style={{width: '100%', height: '100vh', overflowY: 'scroll',}} translate="no" className='container-funcionarios'>
           
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Dropdown renderToggle={renderIconButton} placement="rightStart">
                <Dropdown.Item icon={<AddOutlineIcon/>} onClick={()=>setOpen(true)}>Novo</Dropdown.Item>
                <Dropdown.Menu title="Importar">
                <Dropdown.Item >XLSX</Dropdown.Item>
                </Dropdown.Menu>   
            </Dropdown>

            <Input  placeholder='Search...' style={{maxWidth: 280, margin: 3}} onChange={(e)=>setSearchQuery(e)}/>
            </div>
            <>
            {Array.isArray(funcionario) && funcionario.length > 0 ? (
                <>
                {funcionario.map((item, index)=>(
                <List key={index} divided verticalAlign='middle' translate="no">
                <List.Item>
                    <List.Content floated='right'>
                        <Button color='blue' icon='edit'/>
                        <Button color='red' icon='delete'/>
                    </List.Content>
                    <List.Content>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                            <div>
                                <LetteredAvatar  name={`${item?.first_name?.slice(0, 1)}`}/>
                            </div>
                            <div style={{paddingLeft: '10px'}}>
                                <ul style={{listStyle:'none', marginLeft: '-40px', fontFamily: 'Open Sans, sans-serif', fontWeight: 600,}}>
                                    <li style={{marginBottom: 5}}>
                                        {item?.first_name} {item?.last_name}
                                    </li>
                                    <li translate="no" style={{marginBottom: 5}}>
                                        {item?.email}
                                    </li>
                                    <li>
                                    {item?.is_application_access && item?.is_web_access ? (
                                        <>
                                        <Icon name='android' color='green'/>
                                        <Icon name='world' color='blue'/>
                                        </>
                                    ) : item?.is_application_access ? (
                                        <Icon name='android' color='green'/>
                                    ) : item?.is_web_access ? (
                                        <Icon name='world' color='blue'/>
                                    ) : null}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </List.Content>
                </List.Item>
                </List>
                ))}
                </>
            ):(
                <>
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                </>
            )}
            
            </>

            <Drawer placement='right' open={open} onClose={() => setOpen(false)}>
            <NotificationContainer/>
            <Drawer.Header>
            <Drawer.Title>Acessos</Drawer.Title>
            <Drawer.Actions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={openEditor === false ? HandleSubmit:HandleUpdade} color='blue' appearance="primary">
                Confirm
                </Button>
            </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body>
            {loader ?(
                <Loader backdrop content="loading..." vertical />
            ):(
                <Form fluid>
                <Form.Group controlId="tag">
                <Form.ControlLabel>Tag do Veiculo</Form.ControlLabel>
                <Form.Control 
                    name="tag"
                    value={names?.tag}
                    onChange={(e)=>HandleChange('tag', e)}
                />
                </Form.Group>



                <Form.Group controlId="placa">
                <Form.ControlLabel>Placa do Veiculo</Form.ControlLabel>
                <Form.Control 
                    name="placa"
                    value={names?.placa}
                    onChange={(e)=>HandleChange('placa', e)}
                />
                </Form.Group>


                <Form.Group controlId="autorização">
                <Form.Control 
                    name="checkbox" 
                    accepter={Checkbox} 
                    inline
                    checked={names.autorizacao === true ? true:false}
                    >
                  Fluxo autorizado?  
                </Form.Control>
                </Form.Group>               
                </Form>
            )}    
            

            </Drawer.Body>
        </Drawer>


        </div>
        </>
    )
}
export default AcessosMap


