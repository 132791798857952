import React, { useEffect, useRef, useState } from 'react';
import ApexCharts from 'apexcharts';
import axios from 'axios';
import { BASE_URL } from '../api/api';
import { useSelector } from 'react-redux';


const LeaderBoardUsuarios = (props) => {
  const chartRef = useRef(null);
  const state = useSelector(state => state.auth)

  

  useEffect(() => {
    if (props?.data?.length > 0) {
      const options = {
        chart: {
          type: 'pie',
          width: 400,    // largura do gráfico
          height: 400,
          toolbar: {
            show: false,
          },
        },
        labels: props?.data?.map(usuario => usuario?.destino),
        legend: {
          position: 'bottom',
        },
      
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: '100%',
                height: 'auto',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      };

      const series = props?.data?.map(usuario => usuario?.total);

      const chartOptions = {
        ...options,
        series: series,
      };

      const chart = new ApexCharts(chartRef.current, chartOptions);
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [props?.data]);

  return <div ref={chartRef} />;
};

export default LeaderBoardUsuarios;
