import './styles.css';
import { SelectPicker, InputPicker, Progress, Divider, IconButton, ButtonToolbar } from 'rsuite';
import { useState, useEffect } from 'react';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import LineChartItem from '../../components/LineChart';
import TableFrota from '../../components/TableFrota';
import Lottie from 'react-lottie-player'
import animation from '../../assets/animation.json';
import { BASE_URL } from '../../api/api';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import PageNextIcon from '@rsuite/icons/PageNext';

const data = ['Diario', 'Mensal'].map(
    item => ({ label: item, value: item })
  );


const ReelFrota = () =>{
    const state = useSelector(state=> state.auth)
    const[size, setSize] = useState(false)
    const[play, setPlay] = useState(false)
    const[search, setSearch] = useState('');
    const[loading, setLoading] = useState(false);
    const[anime, setAnime] = useState(false)
    const[result, setResult] = useState([])
    const[option, setOption] = useState('Diario')
    const[veiculo_id, setVeiculo_Id] = useState('')
    const[next, setNext] = useState({
        left: 0,
        right: 10
    });
    const[veiculo_delais, setVeiculo_details] = useState({
        'tag': null,
        'placa': null,
        'autorizacao': null,
        'registros_fora_da_media': 0,
        'registros_dentro_da_media': 0,
        'total_media': 0,
        'desvios': null,
        'total_registros': 0,
        'registros_geral': 0,
        'registros_veiculo':0
        
        

    })
    
    
    const NextPrev = () =>{
        setNext({...next, left: next.right, right: next.right + 10})
    }
    const Previus = () =>{
        if(next.left > 0){
            setNext({...next, left: next.left - 10, right: next.right - 10})
        }
    }

   


    const ParamSelect = (value) =>{
        const selectedVehicle = result.find(veiculo => veiculo.tag === value);
        
        if(selectedVehicle){
            setSize(true)
            setVeiculo_Id(selectedVehicle.tag)
            handleVeiculo(selectedVehicle.tag)
        }
        
    }

    useEffect(() => {
        setPlay(true)
        setLoading(true);
        if(search.length > 0){
            async function fetchEmployees() {
                try {
                const response = await axios.get(`${BASE_URL}api/veiculos/`, {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${state.token}`
                    },
                    params: { q: search }
                });
                setResult(response.data?.results);
                setLoading(false);
                } catch (error) {
                
                }
            }
        
            fetchEmployees();
        }
    }, [search.length]);
    




    const handleVeiculo = (veiculo) =>{
        setVeiculo_details({
            'tag': null,
            'placa': null,
            'autorizacao': null,
            'registros_fora_da_media': 0,
            'registros_dentro_da_media': 0,
            'total_media': 0,
            'total_registros': 0,
            'registros_geral': 0,
            'registros_veiculo':0
        })
        axios({
            url: `${BASE_URL}api/relatorios/veiculo/`,
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
            },
            params: {
                veiculo: veiculo, 
                day: option}
        }).then(function(response){
            setVeiculo_details({
                'tag': response.data?.tag,
                'placa': response.data?.placa,
                'autorizacao':response.data?.autorizacao,
                'registros_fora_da_media': response.data?.registros_fora_da_media,
                'desvios': response?.data?.desvios,
                'registros_dentro_da_media': response.data?.registros_dentro_da_media,
                'total_media':  (response?.data?.registros_fora_da_media / response?.data?.total_registros_veiculo) * 100,
                'registros_veiculo': response?.data?.total_registros_veiculo,
                'registros_geral': response?.data?.total_registros_geral,
                'total_registros': (response?.data?.total_registros_veiculo / response?.data?.total_registros_geral) * 100
                
            })
            setTimeout(()=>{
                setAnime(true)
            },1000)
        })
    }


    

    return(
        <div className='container-root frota'>
            <div className='container-filter frota'>
                <div>  
                    <SelectPicker
                    data={data}
                    defaultValue={option}
                    searchable={false}
                    onSelect={(e)=>setOption(e)}
                    appearance="subtle"
                    
                    /> 
                    <InputPicker 
                    data={result.map(item => ({ label: item.tag, value: item.tag }))}
                    placeholder="Pesquisar"
                    appearance="subtle"
                    onSearch={(e)=>setSearch(e.toUpperCase())}
                    onChange={ParamSelect}
                    style={{marginLeft: 5, width: 240, border: 'none'}}
                    renderMenu={menu => {
                        if (loading) {
                          return (
                            <p style={{ padding: 10, color: '#999', textAlign: 'center' }}>
                              <SpinnerIcon spin /> Loading...
                            </p>
                          );
                        }
                        return menu;
                      }}
                    />
                </div>
            </div>
            <div className='container-bory frota'>
                <div className='container-chart frota'>
                    <div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', padding: 10}}>
                            <strong style={{fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px',letterSpacing: -1.00066, color: '#818181'}}>Detralhes do veiculo</strong>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', margin: 0}}>
                        <Lottie
                            loop={false}
                            animationData={animation}
                            play={play}
                            className={size ? "decrease-size" : "up-size"}
                        />
                        
                        </div>
                        
                        {size && (
                            <div className={anime? "list-frota active": "list-frota"}>
                               
                            <Divider style={{marginTop: -2}}>registros</Divider>
                            <div className="details-chart">
                                
                                <div>
                                    <div style={{width: '6em',display: 'inline-block',marginRight: 10}}>
                                    <Progress.Circle percent={veiculo_delais.total_registros?.toFixed(2)} strokeColor="#079cb6" strokeWidth={8}/>
                                    </div>
                                    <span style={{margin: 5, fontSize: 10, fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px', color: '#818181', marginLeft: -5}}>Total</span>
                                    <sub style={{fontSize: 9 ,fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px', color: '#818181', marginLeft: -5}}>
                                        {veiculo_delais.registros_veiculo}/{veiculo_delais.registros_geral}
                                    </sub>
                                </div>

                                <div>
                                    <div style={{width: '6em',display: 'inline-block',marginRight: 10}}>
                                    <Progress.Circle percent={veiculo_delais?.total_media?.toFixed(2)} strokeColor="#b60707" strokeWidth={8}/>
                                    </div>
                                    <span style={{margin: 5, fontSize: 10, fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px', color: '#818181', marginLeft: -0}}>Desvios</span>
                                    <sub style={{fontSize: 9,fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px', color: '#818181', marginLeft: -0}}>
                                        {veiculo_delais.registros_fora_da_media}/{veiculo_delais.registros_veiculo}
                                    </sub>
                                </div>
                            </div>
                                       
                            </div>
                        )}
                    </div>
                    <div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10}}>
                            <strong style={{fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px',letterSpacing: -1.00066, color: '#818181'}}>Variação da Quilometragem {option === 'Diario'? 'diaria': 'mensal'}</strong>
                            
                            <ButtonToolbar>
                                <IconButton icon={<PagePreviousIcon/>} size='xs' onClick={Previus}/>
                                <IconButton icon={<PageNextIcon/>} size='xs' onClick={NextPrev}/>
                            </ButtonToolbar>
                        </div>
                        <LineChartItem veiculo={veiculo_id} option={option} prev={next} desvios={veiculo_delais.desvios}/>
                    </div>
                </div>
                <div style={{width: '100%', height: '100vh', display: 'flex', flexDirection: 'row'}}>
                    <div className='table-frota'>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', padding: 10}}>
                            <strong style={{fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px',letterSpacing: -1.00066, color: '#818181'}}>Registros</strong>
                        </div>
                        <TableFrota veiculo={veiculo_id} option={option}/>
                    </div>
                    <div className='rosc-chart'>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', padding: 10}}>
                            <strong style={{fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px',letterSpacing: -1.00066, color: '#818181'}}>Registros</strong>
                        </div>
                    </div>
                    

                </div>

                
            </div>
        </div>
    )
}
export default ReelFrota;