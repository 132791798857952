import './styles.css';
import CalendarVisitors from '../../components/Calendar';

const VisitorsScreen = () =>{
    return(
        <div className='container-visitor'>
            <CalendarVisitors/>
        </div>
    )
}
export default VisitorsScreen;