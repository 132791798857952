import { Table, Popover, Whisper, Checkbox, Dropdown, IconButton, Progress } from 'rsuite';
import MoreIcon from '@rsuite/icons/legacy/More';
import { useState, useEffect, memo } from 'react';
import { BASE_URL } from '../../api/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import './styles.css'


const { Column, HeaderCell, Cell } = Table;






const TableFrota = ({veiculo, option}) => {
    const state = useSelector(state=>state.auth)
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [data, setData] = useState([]);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(false);
  

    useEffect(()=>{
        axios({
            url: `${BASE_URL}api/relatorios/veiculo/`,
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
            },
            params: {
                all:veiculo,
                option: option
            }
        }).then(function(response){
            setData(response.data)
            console.log(response.data)
        })
    }, [veiculo, option])

   



    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
        setLoading(false);
        setSortColumn(sortColumn);
        setSortType(sortType);
        }, 500);
    };

    return (
        <Table 
        height={400} 
        rowHeight={50}
        data={data} 
        fillHeight={false}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        >
        <Column width={250} align="left">
            <HeaderCell>Condutor</HeaderCell>
            <Cell dataKey="condutor"/>
        </Column>


        <Column width={80} align="center">
            <HeaderCell>Tag</HeaderCell>
            <Cell dataKey="tag_veiculo"/>
        </Column>

        <Column width={270} align="left">
            <HeaderCell>Frequencia de registros do condutor</HeaderCell>
            <Cell style={{ padding: '10px 0', fontFamily: 'monospace', fontSize: 11 }}>
                {rowData => {
                    let percent = (rowData.frequencia_registro_condutor / rowData.total * 100).toFixed(2);
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Progress percent={percent} showInfo={false} strokeWidth={15} />
                            <span style={{fontSize: '11px', fontFamily: 'monospace', color: '#adacac' }}>{percent}%</span>
                        </div>
                    );
                }}
            </Cell>
        </Column>

        

        <Column width={80} align="center">
            <HeaderCell>Media</HeaderCell>
            <Cell>

            {/* {rowData => (
                <span>{rowData?.media_km_hr_resto}</span>
            )} */}
            </Cell>
        </Column>

        

        </Table>
  );
};

export default memo(TableFrota);

