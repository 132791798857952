import {Icon, Header, Progress, } from 'semantic-ui-react'
import styles from '../css/home.module.css';
import { useEffect, useState } from 'react';


function Funcionariospage(props){
  const [total, setTotal] = useState([]);
  const[data, setData] = useState([]);



  useEffect(()=>{
    const somarZeros = () => {
      let soma = 0;
      for (let i = 0; i < props?.data?.length; i++) {
        if (props?.data[i]?.num_registros === 0) {
          soma++;
        }
      }
      return soma;
    }

    setData(somarZeros())

  },[props?.data])


  useEffect(()=>{
    const somarTodos = () => {
      let soma = 0;
      for (let i = 0; i < props?.data?.length; i++) {
        if (props?.data[i]?.nome != '') {
          soma++;
        }
      }
      return soma;
    }

    setTotal(somarTodos())

  },[props?.data])





    return(
        <div className={styles.funcionariosCard}>
          <Icon bordered inverted color='yellow' name='user' size="large" className={styles.iconVeiculos}/>
          <Header size='medium' className={styles.lblVeiculos}>{data}/{total}</Header>
          <Header.Subheader className={styles.subVeiculos}>Total de Funcionarios</Header.Subheader>
          <Progress progress='value' value={data} total={total} className={styles.progressVeiculos} active color='yellow'/>
        </div>
    )    
}

export default Funcionariospage 