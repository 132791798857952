import { Panel, Button, Icon, Progress, Modal } from 'rsuite';
import './styles.css';
import { useState } from 'react';

const RoadMapScreen = () =>{
    const [arr, setArr] = useState([1, 2, 3, 4, 5, 6, 7])
    const[open, setOpen] = useState(false)







    const ModalDetais = () =>(
        <Modal size='md' open={open} onClose={()=>setOpen(false)}>
            <Modal.Header>
            <Modal.Title>Modal Title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={()=>setOpen(false)} appearance="subtle">
                Cancel
            </Button>
            <Button onClick={()=>setOpen(false)} appearance="primary">
                Ok
            </Button>
            </Modal.Footer>
        </Modal>
    )




    return(
        <div className='container-roadmap'>
            <ModalDetais/>
            <div className='title-roadmap'>
                <span>roadmap</span>
            </div>
            <div style={{overflowX:'scroll', width: '100%'}}>
            <table style={{minWidth: '100%'}}>
                <thead className='th-roadmap'>
                    <tr className='tr-title-roadmap'>
                        <th>
                            <div style={{color: '#ff7d03', padding: 6, borderBottom: '2px solid #ff7d03'}}>
                                <span>
                                    Definir(2)
                                </span>
                            </div>
                        </th>
                        <th>
                            
                            <div style={{color: 'orangered', padding: 6, borderBottom: '2px solid orangered'}}>
                                <span>
                                    Backlog de recursos(1)
                                </span>
                            </div>
                        </th>
                        <th>
                            
                            <div style={{color: 'gray', padding: 6, borderBottom: '2px solid gray'}}>
                                <span>
                                    Em progresso(4)
                                </span>
                            </div>
                        </th>
                        <th>
                            
                            <div style={{color: '#359bfb', padding: 6, borderBottom: '2px solid #359bfb'}}>
                                <span>
                                    Lançamento publico(2)
                                </span>
                            </div>
                        </th>
                        <th style={{color: 'green', fontWeight: 600}}>
                            <div style={{color: 'green', padding: 6, borderBottom: '2px solid green'}}>
                                <span>
                                    Concluido(1)
                                </span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody className='table-roadmap-body'>

                    <td>
                        <div className='div-list'>
                           
                            <Panel header="Agenda" bordered style={{margin: 5, backgroundColor: '#fff'  }} onClick={()=>setOpen(true)}>
                            Criação da agenda de horarios
                            <Progress.Line percent={3}/>
                            </Panel>
                  
                        </div>

                    </td>

                    <td>
                        <div className='div-list'>      
                            
                        </div>

                    </td>

                    <td>

                        <div className='div-list'>                    
                            <Panel header="Protocolos de materiais" bordered style={{margin: 5, backgroundColor: '#fff'  }}>
                            Adição dos protocolos de saida de materiais
                            <Progress.Line percent={20} status="active" />
                            </Panel>
                        </div>

                    </td>

                    <td>
                        <div className='div-list'>

                            

                            <Panel header="" bordered style={{margin: 5, backgroundColor: '#fff'  }}>
                            Descrição da Tarefa
                            <Progress.Line percent={90} status="active" />
                            </Panel>

                            <Panel header="" bordered style={{margin: 5, backgroundColor: '#fff'  }}>
                            Descrição da Tarefa
                            <Progress.Line percent={90} status="active" />
                            </Panel>

                        </div>

                    </td>

                    <td>
                        <div className='div-list'>
                            <Panel header="Solicitação de saida" bordered style={{margin: 5, backgroundColor: '#fff'  }}>
                            Solicitação saida e aprovação de saida de veiculos
                            <Progress.Line percent={100} status="success" />
                            </Panel>

                            <Panel header="Alteração da estrutura" bordered style={{margin: 5, backgroundColor: '#fff'  }}>
                            Alteração da estrutura para Django rest
                            <Progress.Line percent={100} status="success" />
                            </Panel>

                            <Panel header="React PWA" bordered style={{margin: 5, backgroundColor: '#fff'  }}>
                            Alteração do front-end para PWA
                            <Progress.Line percent={100} status="success" />
                            </Panel>

                            <Panel header="Disponibilização em produção" bordered style={{margin: 5, backgroundColor: '#fff'  }}>
                            Disponibilização em um servidor VPS
                            <Progress.Line percent={100} status="success" />
                            </Panel>

                            

                        </div>

                    </td>

             

                </tbody> 
            </table>
            </div>
                
        </div>
    )
}
export default RoadMapScreen