import './styles.css'
import {Avatar, IconButton, Badge, Dropdown, Popover, Whisper, Navbar, Nav, Modal, Button, Checkbox, List} from 'rsuite';
import NoticeIcon from '@rsuite/icons/Notice';
import { useNavigate, useLocation, json } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logout } from '../../store/authSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { BASE_WS} from '../../api/api';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import moment from 'moment';
import TagIcon from '@rsuite/icons/Tag';
import 'intro.js/introjs.css';
import { SolContext } from '../../context/Context';
import TimeIcon from '@rsuite/icons/Time';
import { updateVehicles } from '../../store/vehiclesSlice';





const NavbarComponent = () =>{
  const dispatch = useDispatch()
  const {solicitacoes, setSolicitacoes, solicitacaoData, setSolicitacaoData} = useContext(SolContext);
  const state = useSelector(state => state.auth) 
  const navigate = useNavigate()
  const[data, setData] = useState([])




  const setupWebSocket = (setSolicitacoes, state) => {
    const ws = new WebSocket(`${BASE_WS}?token=${state.token}`);
  
    const requestAllSolicitacoes = () => {
      ws.send(JSON.stringify({ message: "todos" }));
    };

    ws.onopen = () => {
      console.log("Conexão WebSocket aberta");
      requestAllSolicitacoes();
    };
  

    // RECEIMENTO DE MENSAGEM
    ws.onmessage = (event) => {
      const receivedData = JSON.parse(event.data);
      
      if (receivedData.solicitacoes_total) {
        let jsm = JSON.parse(receivedData.solicitacoes_total)
        setSolicitacoes({...solicitacoes, aprovada: jsm?.aprovada, pendente: jsm?.pendente});
      }

      if(receivedData.solicitacao_create){
        setSolicitacoes(prevState => ({
          ...prevState,
          aprovada: (prevState.aprovada || 0) + 1
        }));
        UpdateSolicitacao(receivedData.solicitacao_create.data)
      }

      if(receivedData.solicitacao_update){
        UpdateSolicitacao(receivedData.solicitacao_update.data)
        setSolicitacoes(prevState => ({
          ...prevState,
          pendente: (prevState.pendente || 0) + 1
        }));
      }      
    };
  
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;

    ws.onclose = (event) => {
        console.log(`WebSocket closed with code: ${event.code}, reason: ${event.reason}`);
        if (event.code === 1006 && reconnectAttempts < maxReconnectAttempts) { 
            console.log("Conexão perdida. Tentando reconectar...");
            reconnectAttempts++;
            setTimeout(() => setupWebSocket(setSolicitacoes, state), 5000);
        }
    };
    return ws;
  };
  
  useEffect(() => {
    const ws = setupWebSocket(setSolicitacoes, state);
    return () => {
      ws.close();
    };
  }, []);
  
    
  const UpdateSolicitacao = (data) =>{
    setData(prevState => {
      // Encontra o índice do objeto com o mesmo id, se ele existir
      const index = prevState.findIndex(item => item.id === data.id);
  
      // Se o objeto com o mesmo id foi encontrado, atualize-o
      if (index !== -1) {
        prevState[index] = { ...prevState[index], ...data };
      } else {
        // Se não, adicione o novo objeto ao array
        prevState.push(data);
      }
  
      return [...prevState];
    });
  }

  





  const renderMenu = ({ onClose, left, top, className }, ref) => {
      
    const Logout = () =>{
      dispatch(logout())
      navigate('/')
    }


      const handleSelect = eventKey => {
        onClose();
      };
      return (
        <Popover ref={ref} className={className} style={{ left, top }} full open={false}>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item panel style={{ paddingLeft: 10, width: 160, paddingRight: 10}}>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
              <span>
                Usuario
              </span>
              <strong>{state?.firstName} {state?.lastName}</strong>
              </div>
            
          </Dropdown.Item>
          {state?.isAdmin ?(
            <>
            <Dropdown.Item divider />
            <Dropdown.Item onClick={()=>navigate('/admin')}>Administrador</Dropdown.Item>
            <Dropdown.Item divider />
            </>
          ):(
            <Dropdown.Item divider />
          )}
          <Dropdown.Item onClick={()=>navigate('/perfil')}>Perfil</Dropdown.Item>
          <Dropdown.Item onClick={Logout}>Sair</Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
  }; 











  const renderMenuNotifiations = ({ onClose, left, top, className }, ref) => {
   
    const getCustomText = (timestamp) => {
      const diff = moment().diff(moment(timestamp), 'hours');
      return `Há ${diff} horas atrás`;
    };

      return (
        <Popover ref={ref} className={className} style={{ left, top, padding: 5}} title={<span style={{fontSize: 12, color: 'gray'}}>Atualizações</span>} full>
          {data.map((item, index) => (
            
            <Dropdown.Menu key={item.id}>
            <Dropdown.Item divider />
            <Dropdown.Item panel style={{ width: 160}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', fontFamily: 'Arial', fontSize: 11}}>
                <strong style={{fontSize: 10}}>
                <TimeIcon color='orange' style={{top:-3, marginRight: 2, fontSize: '13px'}}/> {getCustomText(state?.isAprover ? item?.data_da_solicitacao : item?.data_da_aprovacao)}
                </strong>
                <div style={{margin: 1, padding: 5, display: 'flex', flexDirection: 'row'}}>
                  {state.isAprover ? (
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                      <TagIcon style={{color: item?.status === 'pendente' ? 'yellow' : '', fontSize: '20px', marginRight: 2}}/>
                      <span style={{fontSize: '9px'}}>A uma nova solicitação para o veiculo {item?.veiculo?.tag}</span>
                      </div>
                  ):(
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <TagIcon style={{color: item?.status === 'aprovada' ? 'green' : 'red', fontSize: '20px', marginRight: 2}}/>
                      <span style={{fontSize: '9px'}}>Sua solicita para o veiculo {item?.veiculo?.tag} foi {item?.status}</span>
                    </div>
                  )}
                </div>  
                </div>
            </Dropdown.Item>
            </Dropdown.Menu>
          )).slice(0,3)}
        </Popover>
      );
  }; 


 



  return(
    <>
    <div className="invisible-element" style={{ display: 'none' }}/>
    <nav style={{height: 50, display:'flex', flexDirection: 'row', alignItems: 'center', padding: 0, justifyContent: 'space-between', flex: 1}}> 
        <div className='nav-step' class='nav-step' style={{display:'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}> 
          <Whisper placement="bottomEnd" trigger="click" speaker={renderMenuNotifiations}>
            <Badge content={data.length > 0 ? data.length: false}>
              <IconButton icon={<NoticeIcon/>} size="sm" style={{margin: 0}}/>
            </Badge>
          </Whisper>
          <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu}>
            <Avatar circle style={{ background: '#868686', cursor: 'pointer', margin: 5}}>
              <span style={{fontSize: '15px'}}>
              {state?.firstName?.slice(0, 1)}{state?.firstName?.slice(1,2)}
              </span>
            </Avatar>
          </Whisper>
        </div>
    </nav>
    </>
    
      
  )
}
export default NavbarComponent;