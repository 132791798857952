import {useEffect, useState, memo } from 'react';
import './styles.css';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../api/api';
import moment from 'moment';




function LineChartItem({ veiculo, option, prev, desvios }){
    const state = useSelector(state=>state.auth)
    const[data, setData] = useState([])
    const[veiculo_id, setVeiculo] = useState(null)




    useEffect(() => {
        if(veiculo != veiculo_id && veiculo != ''){
            async function fetchEmployees() {
                try {
                const response = await axios.get(`${BASE_URL}api/relatorios/media/`, {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${state.token}`
                    },
                    params: { 
                        veiculo: veiculo,
                        option: option

                    }
                });
                console.log(response.data)
                setData(response.data);
                } catch (error) {
                
                }
            }
        
            fetchEmployees();
        }
    }, [veiculo, option]);
   
    
    const formattedDates = data.slice(prev.left, prev.right).map(item => {
      if (item?.date_only) {
        return moment(item.date_only).format("DD MMMM");;
        
      }else if(item?.month){
        return moment(item?.month).format('MMMM YYYY')
      } else {
        return "";
      }
    });



    const options = {
      chart: {
          type: 'area',
          fontFamily: 'inherit',
          height: 'auto',
          parentHeightOffset: 0,
          toolbar: {
            show: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          stacked: true   
      },
      
      plotOptions: {
        bar: {
          columnWidth: '50%'
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 1
      },
      grid: {
        padding: {
          top: -20,
          right: 0,
          left: -4,
          bottom: -15
        },
        
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      xaxis: {
        categories: formattedDates,
        tooltip: {
          enabled: true
        },
        axisBorder: {
          show: true
        },
      },
      yaxis: {
        labels: {
          padding: 4
        }
      },
      
      legend: {
        show: false
      }
    }

    const series = [{
      name: 'Quilometragem',
      data: data.slice(prev.left, prev.right).map(item => item?.km_hr_resto).filter(value => value >= 0)
    }]


    



    return (
      <div style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'center', width: '100%'}}>
        <Chart options={options} series={series} type="area" height={250}/>
      </div>
    )
    
}

export default memo(LineChartItem);