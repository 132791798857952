
import { Icon, Button, Tab, Modal, Form} from 'semantic-ui-react'
import {Badge } from 'rsuite';
import { useEffect, useState, useContext} from 'react';
import './styles.css';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../api/api';
import axios from 'axios';
import SolicitacaoPendente from '../../components/SolicitacaoPen';
import SolicitacaoConcluida from '../../components/SolicitacaoCon';
import TodasSoliciacoes from '../../components/TodasSolicitacoes';
import FormSocitiacao from '../../components/Form';
import { SolContext } from '../../context/Context';






const SociticaoScreen = () =>{
    const {solicitacoes} = useContext(SolContext)
    const[data, setData] = useState({
      aprovada: null,
      pendente: null
    })
    
    

    useEffect(()=>{
      setData({...data, aprovada: solicitacoes.aprovada, pendente: solicitacoes.pendente})
    },[solicitacoes])




    const panes = [
      {
        menuItem: {
          key: 'pendentes',
          icon: 'bookmark',
          content: (
            <Badge
              content={data.pendente ? data.pendente: false}
            >
              <span style={{ fontSize: 12 }}>Pendentes</span>
            </Badge>
          ),
        },
        render: () => (
          <Tab.Pane attached={false} className='panes-container'>
            <SolicitacaoPendente/>
          </Tab.Pane>
        ),
      },
      {
        menuItem: {
          key: 'concluidas',
          icon: 'check circle',
          content: (
            <Badge
              content={data.aprovada ? data.aprovada: false}
            >
              <span style={{ fontSize: 12 }}>Aprovadas</span>
            </Badge>
          ),
        },
        render: () => (
          <Tab.Pane attached={false} className='panes-container'>
            <SolicitacaoConcluida/>
          </Tab.Pane>
        ),
      },
      {
        menuItem: { key: 'nova', icon: 'plus circle', content: (<span style={{ fontSize: 12 }}>Nova</span>) },
        render: () => (
          <Tab.Pane attached={false} className='panes-container'>
            <FormSocitiacao/>
          </Tab.Pane>
        ),
      },
    ];
    
    if (window.innerWidth >= 765) {
      panes.push({
        menuItem: { key: 'todas', icon: 'tags', content: (<span style={{fontSize: 11}}>Todas</span>) },
        render: () => (
          <Tab.Pane attached={false} className='panes-container'>
            <TodasSoliciacoes/>
          </Tab.Pane>
        ),
      });
    }
    

   


    



    return( 
      <div className='container-solicitacao'>
        <Tab className='tabs' menu={{ secondary: true, pointing: true }} panes={panes} />
      </div>
      
        
    )
}
export default SociticaoScreen