import { useEffect, useState, useContext } from "react";
import { BASE_URL } from "../../api/api";
import { useSelector } from "react-redux";
import anime from '../../assets/loader.json'
import axios from 'axios';
import img from '../../assets/form.jpg'
import { Panel, Button, Drawer, Modal, List, Loader, Input } from 'rsuite';
import { Icon } from "semantic-ui-react";
import moment from "moment";
import 'moment-timezone';
import './styles.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'intro.js/introjs.css';
import Lottie from "react-lottie-player";

const SolicitacaoPendente = () =>{
    const state = useSelector(state=>state.auth)
    const[data, setData] = useState([]);
    const[open, setOpen] = useState(false)
    const[IsClick, setIsClick] = useState([])
    const[openModal, setOpenModal] = useState(false)
    const[OpenCancel, setOpenCancel] = useState(false)
    const[values, setValues] = useState([])
    const[loading, setLoading] = useState(false)
    const[motivo, setMotivo] = useState(null)




    

    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get(`${BASE_URL}aprovacoes/solicitacao/`, {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${state.token}`
          },
          params: { p: 'p' }
        });
        setData(response.data)
        setLoading(false)
 
      } catch (error) {
        console.error('Error:', error);
        setLoading(false)
      }
    };

    useEffect(() => {
      fetchData();
    }, []);  


      const HandleAprov = (value) => {
        axios({
          url: `${BASE_URL}aprovacoes/solicitacoes/aprovar/`,
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${state.token}`
          },
          data:{
            id: value.id,
            status: 'aprovada'
          }
        })
        .then((response) => {
          NotificationManager.success('Solicitação Aprovada', 'Sucesso', 1500);
          fetchData();
          setOpenModal(false)
        })
        .catch((error) => {
          NotificationManager.error(`${error.message}`, 'Error', 1500);
        });
      }
      


    const HandleCancel = (value) =>{
      axios({
        url: `${BASE_URL}aprovacoes/solicitacoes/aprovar/`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${state.token}`
        },
        data:{
          id: value.id,
          status: 'negada'
        }
      }).then((response) => {
        NotificationManager.success('Solicitação Negada', 'Sucesso', 1500);
        fetchData();
      })
      .catch((error) => {
        NotificationManager.error(`${error.message}`, 'Error', 1500);
      });
    }  


    const Materials = ()=>(
      <Modal open={openModal} onClose={()=>setOpenModal(false)} overflow={true}>
        <Modal.Header>
          <Modal.Title>Materiais</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{width: '100%',height: 30, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}> 
            <span>
              Nome
            </span>
            <span>
              Quantidade
            </span>
          </div>
          {values?.map((item, index)=>(
            <List key={index}>
              
            <List.Item>
              <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', margin: 5}}>
                <span>
                  {item?.nome}
                </span>
                <span>
                  {item?.quantidade}
                </span>
              </div>
            </List.Item>
            </List>
          ))}
        </Modal.Body>
        <Modal.Footer>
          
          <Button onClick={()=>setOpenModal(false)} appearance="subtle">
            Sair
          </Button>
        </Modal.Footer>
      </Modal>
    )
      





    const CancelarSolicitacao = () =>{
      if(motivo === null){
        NotificationManager.error(`O Motivo do cancelamento e obrigatório`, 'Error', 1500);
        return;
      }

      axios({
        url: `${BASE_URL}aprovacoes/solicitacoes/aprovar/`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${state.token}`
        },
        data:{
          id: IsClick?.id,
          status: 'cancelada',
          motivo: motivo
          
        }
      }).then((response) => {
        NotificationManager.success('Solicitação Cancelada', 'Sucesso', 1500);
        fetchData();
        setOpenCancel(false)
      })
      .catch((error) => {
        NotificationManager.error(`${error.message}`, 'Error', 1500);
      });
    }  

      
    return(
      <>
      <Materials/>
      <Modal open={OpenCancel} onClose={()=>setOpenCancel(false)} overflow={true}>
        <Modal.Header>
          <Modal.Title>Cancelar Solicitação {IsClick.veiculo?.tag}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input as="textarea" rows={3} placeholder="Motivo de cancelamento" value={motivo} onChange={(text)=>setMotivo(text)}/>
        </Modal.Body>
        <Modal.Footer>
          
          <Button onClick={()=>setOpenCancel(false)} appearance="subtle">
            Sair
          </Button>

          <Button onClick={CancelarSolicitacao} appearance="primary" style={{backgroundColor: 'red'}}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='container-pane-pen'>
          <div className="container-cards-pen">   
          {loading && (
            <div style={{width: '100%', minHeight: '50em', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}}>
              <Lottie
              loop={true}
              animationData={anime}
              play={true}
              style={{height: '40%', width: '40%'}}
            />
            </div>
          )}
            {data.map((item, index) => (
              <Panel shaded bordered bodyFill
                key={item.id}
                className="card-pen pendente"
                onClick={()=>{setOpen(true); setIsClick(item)}}
               
              > 
                <img src={img} style={{ width: '100%', height: '90%'}}/>
                <Panel key={item.id} style={{fontFamily: 'Open Sans, sans-serif', fontWeight: 600}} header={item.status}>
                <div style={{fontFamily: 'Open Sans, sans-serif', fontWeight: 600, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', fontSize: 12, marginTop: -15}}>
                <span>COD: {item?.numero_identificador?.toUpperCase()}</span>
                <span>Veiculo: {item?.veiculo?.tag}</span>
                <span>Solicitante: {item?.solicitante?.first_name} {item?.solicitante?.last_name}</span>
                <small style={{fontSize: 10}}>Data de saida: {moment.utc(item?.data_saida).format('llll')}</small>
                
                </div>
                </Panel>  
              </Panel> 
            ))}
          </div>
          
          <NotificationContainer/>
          <Drawer size='xs' placement='right' open={open} onClose={() => setOpen(false)} className="drawer-menu">
          <Drawer.Header>
            <Drawer.Title>COD: {IsClick?.numero_identificador?.toUpperCase()}</Drawer.Title>
            
          </Drawer.Header>
          <Drawer.Body style={{padding: 0}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} className="tutorial">
                <div>
                  <img src={img} style={{ width: '100%', height: '50%'}}/>
                </div>
                <div className='div-details' style={{fontFamily: 'Open Sans, sans-serif', fontWeight: 600, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', fontSize: 12, padding: 10}}>
                <span><Icon name='id badge'/>Funcionario: {IsClick?.funcionario?.nome}</span>
                  <span><Icon name='address card outline'/>Matricula: {IsClick?.funcionario?.matricula}</span>
                  <span><Icon name='cubes'/>Centro de custo: {IsClick?.funcionario?.contrato?.nome}</span>
                  <span><Icon name='car'/>Veiculo: {IsClick?.veiculo?.tag}</span>
                  <span><Icon name='tag'/>Placa: {IsClick?.veiculo?.placa}</span>
                  <span><Icon name='question circle'/>Motivo: {IsClick?.motivo}</span>
                  <span><Icon name='road'/> Destino: {IsClick?.destino}</span>
                  <span><Icon name='user'/>Solicitante: {IsClick?.solicitante?.first_name} {IsClick?.solicitante?.last_name}</span>
                  <span><Icon name='users'/> Aprovador: {IsClick?.aprovador?.first_name} {IsClick.aprovador?.last_name}</span>
                  <span><Icon name='calendar alternate outline' color='red'/>Saida: {moment.utc(IsClick?.data_saida).format('llll')}</span>
                  <span><Icon name='calendar alternate outline' color="green"/>Entrada: {IsClick?.data_retorno === null ? 'Indefinida': moment.utc(IsClick?.data_retorno).format('llll')}</span>    
                  {IsClick.equipamento1 && (
                    <span><Icon name='truck'/> Equipamento: {IsClick.equipamento1}</span>
                  )} 
                  {IsClick.equipamento2 && (
                    <span><Icon name='truck'/> Equipamento: {IsClick.equipamento2}</span>
                  )}               
                  <span><Icon name='sitemap'/>Materiais: {IsClick?.material?.length === 0 ? 'Não': <a onClick={()=>{setOpenModal(true); setValues(IsClick?.material)}}>Sim</a>}</span>
                  
                  </div>
                  <div>
                    {state?.isAprover &&(
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%', marginTop: '10%'}}>
                        <Button color='red' appearance="primary" style={{width: '40%', margin: 5, fontSize: 12}} onClick={()=>HandleCancel(IsClick)}>Negar</Button>
                        <Button color='blue' appearance="primary" style={{width: '40%', margin: 5, fontSize: 12}} onClick={()=>HandleAprov(IsClick)}>Aprovar</Button>
                      </div>
                    )}
                  </div>
                  {state?.isAprover === false && state.firstName === IsClick?.solicitante?.first_name ? (
                      <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 10}} className="div-cancel">
                        <Button appearance="primary" style={{backgroundColor: 'red', width: '100%', margin: 5}} onClick={()=>{setOpenCancel(true); setOpen(false)}}>Cancelar</Button>
                      </div>
                    ) : null}
                </div>
                
          </Drawer.Body>
        </Drawer>
      </div>
      </>
        
    )
}
export default SolicitacaoPendente;