import { useRef, useState, memo } from 'react';
import './styles.css';
import Chart from 'react-apexcharts';
import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import PageNextIcon from '@rsuite/icons/PageNext';
import { ButtonToolbar, IconButton, Badge } from 'rsuite';

const RoscChard = ({data, childChange, activeRosc}) =>{
  const[next, setNext] = useState(4);
  const[start, setStart] = useState(0);
  const [content, setContent] = useState(true);
  const[end, setEnd] = useState(4);


 
  const options = {
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
          donut: {
            size: '75%'
          },
          offsetY: 0
        },
        stroke: {
          colors: undefined
        }
      },
      colors: ['#5f71e4', '#2dce88', '#fa6340', '#f5365d', '#13cdef'],
      labels: data.slice(start, end)?.map(item => `${item?.aprovador__first_name} ${item?.aprovador__last_name}`),
      legend: {
        position: 'bottom',
        offsetY: 1
      },
      chart: {
        events: {
          dataPointSelection: (event, chartContext, { dataPointIndex }) => {
            const selectedValue = data.slice(start, end)[dataPointIndex];
            childChange(selectedValue?.aprovador__first_name);
            // activeRosc(selectedValue?.aprovador__contrato__nome);
          }
        }
      }
    }

    const series = data.slice(start, end)?.map(item=>item?.total)

    
    const Next = () => {
      if (end < data.length) {
        setStart(prevStart => prevStart + 4);
        setEnd(prevEnd => prevEnd + 4);
        setContent(true)
      }else{
        setContent(false)
      }
    }
  
    const Previous = () => {
      if (start > 0) {
        setStart(prevStart => prevStart - 4);
        setEnd(prevEnd => prevEnd - 4);
      }else{
        setContent(true)
      }
    }

    return(
        <div>
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: -20}}>
            <ButtonToolbar>
                  <IconButton icon={<PagePreviousIcon/>} size='xs'  onClick={Previous}/>
                  <Badge content={content}>
                    <IconButton icon={<PageNextIcon/>} size='xs' onClick={Next}/>
                  </Badge>
                  
              </ButtonToolbar>
          </div>
        <Chart options={options} series={series} type="donut" height={300}/>
        </div>
    )
}
export default memo(RoscChard);



