import {Header, Progress, Icon} from 'semantic-ui-react'
import styles from '../css/home.module.css'
import { useEffect, useState } from 'react'



function Veiculospage(props){
    const[data, setData] = useState([]);
    const[total, setTotal] = useState([]);
 
    useEffect(()=>{
      const somarZeros = () => {
        let soma = 0;
        for (let i = 0; i < props?.data?.length; i++) {
          if (props?.data[i]?.num_registros === 0) {
            soma++;
          }
        }
        return soma;
      }

      setData(somarZeros())

    },[props?.data])




    useEffect(()=>{
      const somarTodos = () => {
        let soma = 0;
        for (let i = 0; i < props?.data?.length; i++) {
          if (props?.data[i]?.tag != '') {
            soma++;
          }
        }
        return soma;
      }

      setTotal(somarTodos())

    },[props?.data])


    


    return(
        <div className={styles.ConVeiculo}>
          <Icon bordered inverted color='green' name='truck' size="large" className={styles.iconVeiculos}/>
          <Header size='medium' className={styles.lblVeiculos}>{data}/{total}</Header>
          <Header.Subheader className={styles.subVeiculos}>Total Veiculos</Header.Subheader>
          <Progress progress='value' value={data} total={total} color='green' className={styles.progressVeiculos} active/>
        </div>
    )
    
}

   

export default Veiculospage