import { Container, Header,Content, Form, ButtonToolbar, Button, Loader, SelectPicker} from 'rsuite';
import { Icon, Tab} from 'semantic-ui-react'
import './styles.css';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../api/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';


const data = ['Não', 'Sim'].map(
    item => ({ label: item, value: item })
);


const ProfileScreen = () =>{
  const state = useSelector(state=>state.auth)
  const[old_password, setOldPassword] = useState('');
  const[new_password, setNewPassword] = useState('');
  const[repeat_pass, setRepeat] = useState('');
  const[loading, setLoading] = useState(false);





  const ChangePassword = () =>{
    setLoading(true)
    axios({
        url: `${BASE_URL}api/change-password/`,
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${state.token}`
        },
        data:{
            old_password: old_password,
            new_password: new_password
        }
    }).then(function(reponse){
        NotificationManager.success('Senha Alterada com sucesso', 'Sucesso', 1500)
        setLoading(false)

    }).catch(function(error){
        NotificationManager.error(error?.response?.data?.error, 'Error', 1500)
        setLoading(false)
    })
  }


  const ValidateTelegram = () =>{
    setLoading(true)
    axios({
        url: `${BASE_URL}api/validar/telegram/`,
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${state.token}`
        }
    }).then(function(reponse){
        NotificationManager.success('Telegram Solicitado veirifique seu email para completar!', 'Sucesso', 2000)
        setLoading(false)

    }).catch(function(error){
        NotificationManager.error(error?.response?.data?.error, 'Error', 1500)
        setLoading(false)
    })
  }



    const panes = [
        
        {
          menuItem: { key: 'Detalhes', icon: 'address card', content: 'Detalhes' },
          render: () => 
          <Tab.Pane attached={false} className='panes'>
            <div style={{width: '100%'}}>
                <ul className='list-details'>
                    <li>
                        Nome: <span>{state.firstName} {state?.lastName}</span>
                    </li>
                    <li>
                        Email: <span>{state?.email}</span>
                    </li>

                    <li>
                        Localização: <span>{state?.location}</span>
                    </li>

                    <li>
                        Acesso Telegram?: <Button onClick={ValidateTelegram} style={{fontSize: '0.8em'}}>{state?.telegram_id === null ? 'Não': 'Sim'}</Button>
                        
                    </li>

                    

                </ul>
              
            </div>
          </Tab.Pane>,
        },
        {
            menuItem: { key: 'Pass', icon: 'key', content: 'Editar senha' },
            render: () => 
            <Tab.Pane attached={false} className='panes'>
              <div style={{width: '100%'}}>
                {loading ? (
                    <Loader backdrop content="loading..." vertical />
                ):(
                <Form autoComplete="new-password" fluid>
                    <Form.Group controlId="current-password">
                    <Form.ControlLabel>Senha Atual</Form.ControlLabel>
                    <Form.Control name="current-password" onChange={(e)=>setOldPassword(e)} autoComplete="new-password"/>
                    </Form.Group>
                    <Form.Group controlId="new-password">
                    <Form.ControlLabel>Nova Senha</Form.ControlLabel>
                    <Form.Control name="new-password" type="password" onChange={(e)=>setNewPassword(e)} autoComplete="new-password"/>
                    </Form.Group>
                    <Form.Group controlId="repeat-password">
                    <Form.ControlLabel>Repetir Senha</Form.ControlLabel>
                    <Form.Control name="repeat-password" type="password" onChange={(e)=>setRepeat(e)} autoComplete="new-password" />
                    </Form.Group>
                    <Form.Group>
                    <ButtonToolbar>
                        <Button appearance="primary" onClick={ChangePassword}>Confirmar</Button>
                    </ButtonToolbar>
                    </Form.Group>
                </Form>
                )}
                   
                
              </div>
            </Tab.Pane>,
        },
    ]


    return(
        <div className="container-perfil">
        <NotificationContainer/>
        <Container>
            <Container>
            <Header className='header'>
                <h3 style={{fontFamily: 'Open Sans, sans-serif', fontWeight: 600, wordSpacing: '0px'}}>Perfil</h3>
            </Header>
            <Content>
                <div className='details'>
                    <div>
                        <div className='details-child-one'>
                            <Icon size='huge' bordered inverted color='blue' circular name='user' style={{fontSize: '5em'}}/>
                            <span>{state?.firstName} {state?.lastName}</span>
                            <strong>{state?.location}</strong>
                        </div>

                        <div className='details-child-tow'>
                            <Tab menu={{ secondary: true, pointing: true }} panes={panes} style={{width: '100%', padding: 5}}/>
                        </div>
                    </div>
                    <div>
                        
                    </div>
                    
                    
                </div>
                
            </Content>
            </Container>
        </Container>
        </div>
    )
}
export default ProfileScreen