
import {  List,  Button,  Icon, Image} from 'semantic-ui-react'
import { useEffect, useState, } from 'react';
import 'semantic-ui-css/semantic.min.css'
import './styles.css'
import 'rsuite/dist/rsuite.min.css';
import MenuIcon from '@rsuite/icons/Menu';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import img from '../../assets/cart.png';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../api/api';
import axios from 'axios';
import CheckIcon from '@rsuite/icons/Check';
import RemindOutlineIcon from '@rsuite/icons/RemindOutline';
import { Drawer, IconButton, Placeholder, Dropdown, Input, Form, Checkbox, Loader, CheckboxGroup, Modal, SelectPicker } from 'rsuite';
import introJs from 'intro.js';
import 'intro.js/introjs.css';



const data = ['Liberado', 'Bloqueado'].map(
    item => ({ label: item, value: item })
);


const cla = ['Transporte de Apoio', 'Transporte Coletivo', 'Transporte Comum'].map(
    item =>({ label: item, value: item })
);

const renderIconButton = (props, ref) => {
    return (
      <IconButton {...props} size="sm" ref={ref}  icon={<MenuIcon/>} style={{backgroundColor: '#dfdede'}}/>
    );
};

function CadastroFrota(){
    const state = useSelector(state=> state.auth)
    const[veiculos, setVeiculos] = useState([]);
    const[loader, setLoader] = useState(false);
    const[names, setNames] = useState({
        'id': '',
        'tag': '',
        'placa': '',
        'autorizacao': true,
        'classificacao': ''
    })

    const[open, setOpen] = useState(false);
    const[openEditor, setOpenEditor] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const[openModal, setOpenModal] = useState(false)
    const[openModalLib, setOpenModalLib] = useState(false)
    const[errorVisible, setErrorVisible] = useState(false)
    const errorMessage = errorVisible ? 'Selecione uma opção' : null;

   
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${BASE_URL}api/veiculos/`, {
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
              }
            });
            setVeiculos(response.data?.results);
          } catch (error) {
            console.error('Error:', error);
          }
        };
    
        fetchData();
    }, []);





    useEffect(() => {
        if(searchQuery.length > 3){
            async function fetchEmployees() {
                try {
                const response = await axios.get(`${BASE_URL}api/veiculos/`, {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${state.token}`
                    },
                    params: { q: searchQuery }
                });
                setVeiculos(response.data?.results);
                } catch (error) {
                
                }
            }
        
            fetchEmployees();
        }
    }, [searchQuery]);
    

  
    useEffect(() => {
        const startTour = () => {
            const intro = introJs();
          
            intro.setOptions({
              steps: [
                {
                  element: '.options',
                  intro: 'Foi adicionada a função de liberar todos e bloquear todos.',
                },
                
              ],
              doneLabel: 'Concluído'
            });
          
            intro.onexit(() => {
              localStorage.setItem('tour_admin', true);
            });
          
            intro.oncomplete(() => {
              // será executado quando o usuário concluir o tour
              localStorage.setItem('tour_admin', true);
            });
          
            // só iniciar o tour se a flag 'tour_solicitacoes_finished' não estiver definida
            if (!localStorage.getItem('tour_admin')) {
              intro.start();
            }
          };
          
          startTour();
          
      }, []);
    


    

    

    const HandleChange = (name, value) =>{
        setNames(prevValues => ({
            ...prevValues,
            [name]: value.toUpperCase()
        }));
    }


    const handleCheckboxChange = (value) => {
        let vl = null
        if(value === 'Bloqueado'){
            vl = false
        }else{
            vl = true
        }

        setNames((prevState) => ({
          ...prevState,
          autorizacao: vl,
        }));
    };
    





    const HandleSubmit = () =>{
        
        
        setLoader(true)
        axios({
            url: `${BASE_URL}api/veiculos/`,
            method: 'POST',
            headers:{
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
            },
            data:names
        }).then(function (response){
            setLoader(false)
            NotificationManager.success('Veiculo inserido', 'Sucesso', 1500);
            setNames(prevValues => ({
                ...prevValues,
                tag: '',
                placa: '',
                autorizacao: true
            }));
            
        }).catch(function (error){
            NotificationManager.error(`${error?.message}`, 'Warning', 1500);
            setLoader(false)
        })
    }


    const HandleUpdade = () =>{
        console.log(names)
        setLoader(true)
        axios({
            url: `${BASE_URL}api/veiculos/${names.id}/`,
            method: 'PUT',
            headers:{
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
            },
            data:names
        }).then(function (response){
            setLoader(false)
            NotificationManager.success('Veiculo atualizado', 'Sucesso', 1500);
            

            const updatedVehicleIndex = veiculos.findIndex(veiculo => veiculo.id === response.data.id);
            if (updatedVehicleIndex !== -1) {
                const updatedVeiculos = [...veiculos];
                updatedVeiculos[updatedVehicleIndex] = response.data;
                setVeiculos(updatedVeiculos);
            }
            
        }).catch(function (error){
            NotificationManager.error(`${error?.message}`, 'Warning', 1500);
            setLoader(false)
        })
        

    }


    


    const HandleBlock = () =>{
        setLoader(true)
        axios({
            url:`${BASE_URL}api/veiculos/auth/`,
            method: 'GET',
            headers:{
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
            },
            params:{
                tipo: false
            }
        }).then(function(response){
            setLoader(false)
            setVeiculos(response.data)
            setOpenModal(false)
        })
    }




    const HandleLib = () =>{
        setLoader(true)
        axios({
            url:`${BASE_URL}api/veiculos/auth/`,
            method: 'GET',
            headers:{
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
            },
            params:{
                tipo: true
            }
        }).then(function(response){
            setVeiculos(response.data)
            setOpenModalLib(false)
            setLoader(false)
        })
    }


    return(
        <>

        <Modal backdrop="static" role="alertdialog" open={openModal} onClose={()=>setOpenModal(false)} size="xs">
            <Modal.Body>
            <RemindOutlineIcon style={{ color: 'red', fontSize: 24 }} />
            Atenção, Todos os veiculos serão bloqueados, confirmar?
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={HandleBlock} loading={loader} appearance="primary">
                Ok
            </Button>
            <Button onClick={()=>setOpenModal(false)} appearance="subtle">
                Cancelar
            </Button>
            </Modal.Footer>
        </Modal>


        <Modal backdrop="static" role="alertdialog" open={openModalLib} onClose={()=>setOpenModalLib(false)} size="xs">
            <Modal.Body>
            <RemindOutlineIcon style={{ color: 'green', fontSize: 24 }} />
            Atenção, Todos os veiculos serão liberados, confirmar?
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={HandleLib} loading={loader} appearance="primary">
                Ok
            </Button>
            <Button onClick={()=>setOpenModalLib(false)} appearance="subtle">
                Cancelar
            </Button>
            </Modal.Footer>
        </Modal>


        <div style={{width: '100%', height: '100vh', overflowY: 'scroll',}} translate="no" className='container-funcionarios'>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', position: 'sticky', top:0, zIndex: 10}}>
            <Dropdown renderToggle={renderIconButton} placement="rightStart" className='options'>
                <Dropdown.Item icon={<AddOutlineIcon/>} onClick={()=>setOpen(true)}>Novo</Dropdown.Item>
                <Dropdown.Item icon={<RemindOutlineIcon/>} onClick={()=>setOpenModal(true)}>Bloquear todos  
                </Dropdown.Item>   
                <Dropdown.Item icon={<CheckIcon/>} onClick={()=>setOpenModalLib(true)}>Liberar todos</Dropdown.Item>
            </Dropdown>

            <Input  placeholder='Search...' style={{maxWidth: 280, margin: 3}} onChange={(e)=>setSearchQuery(e)}/>
            </div>
            <>
            {Array.isArray(veiculos) && veiculos.length > 0 ? (
                <>
                {veiculos.map((item, index)=>(
                <List key={index} divided verticalAlign='middle' translate="no">
                <List.Item>
                    <List.Content floated='right'>
                        <Button color='blue' icon='edit' onClick={()=>{setOpen(true); setNames({...names, id: item?.id,tag: item?.tag, placa: item?.placa, autorizacao: item?.autorizacao, classificacao: item?.classificacao === 'undefined' ? 'Transporte comum': item?.classificacao}); setOpenEditor(true)}}/>
                    </List.Content>
                    <List.Content>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                            <div>
                                <Image src={img}  size='mini' style={{height: 50, width: 50, margin: 3}}/>
                            </div>
                            <div style={{paddingLeft: '10px'}}>
                                <ul style={{listStyle:'none', marginLeft: '-40px', fontFamily: 'Open Sans, sans-serif', fontWeight: 600}}>
                                    <li style={{marginBottom: 5}}>
                                        {item?.tag}
                                    </li>
                                    <li translate="no" style={{marginBottom: 5}}>
                                        {item?.placa === ''? (
                                            <>
                                            SEM PLACA
                                            </>
                                        ):(
                                            <>
                                            {item.placa}
                                            </>
                                        )}
                                    </li>
                                    <li>
                                        {item?.autorizacao ? (
                                            <>
                                            Trafego autorizado: <Icon name='check circle' color='green'/>
                                            </>
                                        ):(
                                            <>
                                            Trafego autorizado: <Icon name='warning sign' color='red'/>
                                            </>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </List.Content>
                </List.Item>
                </List>
                ))}
                </>
            ):(
                <>
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                </>
            )}
            
            </>

            <Drawer placement='right' open={open} onClose={() => setOpen(false)} className='drawer-menu'>
            <NotificationContainer/>
            <Drawer.Header>
            <Drawer.Title>Frota</Drawer.Title>
            <Drawer.Actions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={openEditor === false ? HandleSubmit:HandleUpdade} color='blue' appearance="primary">
                Confirm
                </Button>
            </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body>
            {loader ?(
                <Loader backdrop content="loading..." vertical />
            ):(
                <div>
                <Form fluid>
                <Form.Group controlId="tag">
                <Form.ControlLabel>Tag do Veiculo</Form.ControlLabel>
                <Form.Control 
                    name="tag"
                    value={names?.tag}
                    onChange={(e)=>HandleChange('tag', e)}
                />
                </Form.Group>



                <Form.Group controlId="placa">
                <Form.ControlLabel>Placa do Veiculo</Form.ControlLabel>
                <Form.Control 
                    name="placa"
                    value={names?.placa === '' ? 'SEM PLACA': names?.placa}
                    onChange={(e)=>HandleChange('placa', e)}
                />
                </Form.Group>



                <Form.Group controlId="autorização">
                <Form.ControlLabel>Fluxo de saida</Form.ControlLabel>
                <Form.Control 
                    style={{width: '100%'}}
                    name="checkbox" 
                    accepter={SelectPicker} 
                    data={data}
                    value={names.autorizacao === true ? 'Liberado': 'Bloqueado'}
                    searchable={false}
                    errorMessage={errorVisible}
                    onChange={handleCheckboxChange}
                    >
                </Form.Control>
                </Form.Group>

                <Form.Group controlId="classificao">
                <Form.ControlLabel>Classificação do Veiculo</Form.ControlLabel>
                <Form.Control 
                    style={{width: '100%'}}
                    name="classification" 
                    accepter={SelectPicker} 
                    data={cla}
                    searchable={false}
                    value={names.classificacao}
                    onChange={(value)=>setNames(prevState=>({...prevState, classificacao: value}))}

                    
                    >
                </Form.Control>
                </Form.Group>

                
                </Form>  
                </div>
                
            )}    
            

            </Drawer.Body>
        </Drawer>
        </div>
        </>
    )
}

export default CadastroFrota