import React from 'react';
import NavbarComponent from '../navbarComponent';
import SidebarComponent from '../Sidebar';
import './styles.css';
import { useState, useEffect } from 'react';
import { Footer, Button } from 'rsuite';
import { Icon } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom';

const Layout = ({ children}) => {
  const navigate = useNavigate()

  return (
    <div className='container-layout'>
      <div className='layout-child-one'>
        <NavbarComponent />
        <div className='layout-side'>
          <SidebarComponent/>
        </div>
      </div>
      <div  className='layout-content'>
        <div className='layout-content-child-one'>

        </div>
        <div className='layout-content-child'>
          {children}
        </div>

        <div className="container-nav-button">
          <nav className="menu-nav">
            
            <Button href="#" onClick={()=> navigate('/solicitacao')} className="menu-item one">
              <span className="material-icons"><Icon   name='bookmark'/></span>
              <span className="menu-item-label">Solicitações</span>
            </Button>

            <Button href="#"  onClick={()=> navigate('/home')} className="menu-item tow">
              <span className="material-icons"><Icon  name='home'/></span>
              <span className="menu-item-label">Inicio</span>
            </Button>


            <Button href="#" onClick={()=> navigate('/relatorios/solicitacao')} className="menu-item tree">
              <span className="material-icons"><Icon  name='chart line'/></span>
              <span className="menu-item-label">Relatorio</span>
            </Button>
          </nav>
        </div>
      </div>
      
      
    </div>
  );
};

export default Layout;


