import { Table, Popover, Whisper, Dropdown, IconButton, Pagination, Button, Modal, Input, List, DateRangePicker } from 'rsuite';
import { useState, useEffect, memo } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../api/api';
import moment from 'moment';
import MenuIcon from '@rsuite/icons/Menu';







const { Column, HeaderCell, Cell } = Table;
const rowKey = 'id';


const NameCell = ({ rowData, dataKey, ...props }) => {
 
  const funcionario = rowData[dataKey]

  const speaker = (
    <Popover title={<span style={{fontSize: 11}}>Dealhes</span>}>
      <p>
        <b style={{fontSize: 10}}>Nome:</b> {funcionario.nome}
      </p>
      <p style={{fontSize: 10}}>
        <b style={{fontSize: 10}}>Contrato:</b> {funcionario.contrato.nome}
      </p>
      <p style={{fontSize: 10}}>
        <b style={{fontSize: 10}}>Matricula:</b> {funcionario.matricula}
      </p>
    
    </Popover>
  );
  
  return (
    <Cell {...props}>
      <Whisper placement="top" speaker={speaker}>
        <span style={{fontSize: 10.5,}}>{funcionario.nome}</span>
      </Whisper>
    </Cell>
  );
};














const locale = {
  sunday: 'Dom',
  monday: 'Seg',
  tuesday: 'Ter',
  wednesday: 'Qua',
  thursday: 'Qui',
  friday: 'Sex',
  saturday: 'Sab',
  ok: 'OK',
  today: 'Hoje',
  yesterday: 'Ontem',
  last7Days: 'Últimos 7 Dias',
  hours: 'Horas',
  minutes: 'Minutos',
  seconds: 'Segundos',
};








const TodasSoliciacoes = () => {
  const state = useSelector(state=>state.auth)

  const[defaultdata,setData] = useState([]);
  const[filteredData, setFilteredData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const[open, setOpen] = useState(false)
  const[material, setMaterial] = useState([])
  const [height, setHeight] = useState(window.innerHeight);
  const [sortColumn, setSortColumn] = useState('status');
  const [sortType, setSortType] = useState('desc');
  const[loading, setLoading] = useState(false);
  const[openModalCalendar, setOpenModalCalendar] = useState(false)



  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);


    return () => window.removeEventListener('resize', handleResize);
  }, []);



  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  const data = defaultdata.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });



  
  
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
        try {
            const response = await axios.get(`${BASE_URL}aprovacoes/solicitacao/search/`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${state.token}`
                },
                
            });   
            setData(response.data);  
            setFilteredData(response.data);
            setLoading(false)
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    fetchData();
}, []);



const updateFilter = (value) => {
  if(value != null){
    if(value.length > 1){
      setLoading(true)
      

      let filtered = filteredData.filter(item => 
        item.aprovador?.first_name.toUpperCase().includes(value) ||
        item.solicitante?.first_name.toUpperCase().includes(value) ||
        item.funcionario?.nome?.toUpperCase().includes(value) ||
        item.veiculo?.tag?.toUpperCase().includes(value) ||
        item.veiculo?.placa?.toUpperCase().includes(value) ||
        item?.status.toUpperCase().includes(value)
      );
      setData(filtered)
      setLoading(false)
    }else{
      setData(filteredData);
      setLoading(false)
    }
  }
  
  
  
  
};



  const ModalMaterial = () =>(
    <Modal open={open} onClose={()=>setOpen(false)} overflow={true}>
        <Modal.Header>
          <Modal.Title>Materiais</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{width: '100%',height: 30, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 12, position: 'sticky', top:0, zIndex: 10, backgroundColor: '#fff'}}> 
              <span>
                Nome
              </span>
              <span>
                Quantidade
              </span>
            </div>
            {material?.map((item, index)=>(
              <List key={index}>
                
              <List.Item>
                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', margin: 5, fontSize: 11}}>
                  <span>
                    {item?.nome}
                  </span>
                  <span>
                    {item?.quantidade}
                  </span>
                </div>
              </List.Item>
              </List>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>setOpen(false)} appearance="primary">
            Ok
          </Button>
          
        </Modal.Footer>
    </Modal>
  )
 
  

  const FilterDate = (value) =>{
    
    let startDate = new Date(value[0]).toISOString().split('T')[0];
    let endDate = new Date(value[1]).toISOString().split('T')[0];

    let filteredArray = filteredData.filter(obj => {
      let date = new Date(obj.data_da_solicitacao).toISOString().split('T')[0];
      return date >= startDate && date <= endDate;
    });
    if(filteredArray.length > 0){
      setOpenModalCalendar(false)
    }
    setData(filteredArray)
    
    
  }
  

  const ModalCalendar = () =>(
    <Modal open={openModalCalendar} size="xs" onClose={()=>setOpenModalCalendar(false)}>
        <Modal.Header>
        </Modal.Header>
        <Modal.Body style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
            <label>
              Data: 
            </label>
          </div>
          <DateRangePicker showOneCalendar locale={locale} placeholder="Selecione a Data" style={{width: '100%'}} onChange={(text)=>FilterDate(text)}/>
        </Modal.Body>
        
    </Modal>
  )
 



  const renderFilter = ({ onClose, left, top, className }, ref) => {
    const handleSelect = eventKey => {
      onClose();
    };
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu>
          <Dropdown.Menu title="Status">
            <Dropdown.Item eventKey={1} onClick={()=>updateFilter('CONCLUIDA')}>Concluida</Dropdown.Item>
            <Dropdown.Item eventKey={2} onClick={()=>updateFilter('PENDENTE')}>Pendente</Dropdown.Item>
            <Dropdown.Item eventKey={3} onClick={()=>updateFilter('CANCELADA')}>Cancelada</Dropdown.Item>
            <Dropdown.Item eventKey={4} onClick={()=>updateFilter('NEGADO')}>Negada</Dropdown.Item>
          </Dropdown.Menu>
          <Dropdown.Item eventKey={1} onClick={()=>setOpenModalCalendar(true)}>Filtro por data</Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };









  return (
    <div style={{width: '100%'}}>
      <ModalCalendar/>
      <ModalMaterial/>
      <div style={{maxWidth: '17%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', position: 'sticky', top: 0, zIndex: 10, marginBottom: 20}}>
        
        <Whisper placement="bottomStart" trigger="click" speaker={renderFilter}>
          <IconButton icon={<MenuIcon/>} style={{marginRight: 5}}/>
        </Whisper>
        
        <Input style={{fontSize: 12}} placeholder='search' onChange={(text)=>updateFilter(text.toUpperCase())}/>
      </div>
      <Table 
      data={data}
      loading={loading}
      height={height}
      style={{fontSize: 11, overflowX: 'hidden', width: '100%'}}
      onSortColumn={(sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
      }}
      sortColumn={sortColumn}
      sortType={sortType}
      >  


        <Column width={100} fullText>
          <HeaderCell>N°</HeaderCell>
          <Cell>
          {(rowData) => (   
            <span>{rowData?.numero_identificador?.toUpperCase()}</span>
          )}
          </Cell>
        </Column>


        <Column width={100} fullText>
          <HeaderCell>Solicitante</HeaderCell>
          <Cell>
          {(rowData) => (   
            <span>{rowData?.solicitante.first_name} {rowData?.solicitante.last_name}</span>
          )}
          </Cell>
        </Column>

        <Column width={100} fullText>
          <HeaderCell>Aprovador</HeaderCell>
          <Cell>
          {(rowData) => (
            rowData.aprovador_real === null ? (
              <span>{rowData?.aprovador.first_name} {rowData?.aprovador.last_name}</span>
            ):(
              <span>{rowData?.aprovador_real.first_name} {rowData?.aprovador_real.last_name}</span>
            )
          )}
          </Cell>
          
        </Column>


        <Column width={100} fullText flexGrow={2}>
          <HeaderCell>Motorista</HeaderCell>
          <NameCell dataKey='funcionario'/>
        </Column>


        <Column width={80}>
          
          <HeaderCell>
            Tag
          </HeaderCell>
         
          <Cell dataKey="veiculo" >
          {(rowData) => (
            <span>{rowData?.veiculo.tag}</span>
          )}
          
          </Cell>
        </Column>


        <Column width={80}>
          
          <HeaderCell>
            Placa
          </HeaderCell>
         
          <Cell dataKey="veiculo" >
          {(rowData) => (
            <span>{rowData?.veiculo.placa}</span>
          )}
          
          </Cell>
        </Column>

        

        
        <>

        <Column width={120} fullText flexGrow={1}>
          <HeaderCell>Motivo</HeaderCell>
          <Cell style={{fontSize: 10.5}} dataKey="motivo" />
        </Column>


        <Column width={100} fullText flexGrow={1}>
          <HeaderCell>Destino</HeaderCell>
          <Cell style={{fontSize: 10.5}} dataKey="destino" />
        </Column>


        <Column width={100} fullText align='center' flexGrow={2}>
          <HeaderCell>Tempo decorrido entre Solicitação e Aprovação</HeaderCell>
          <Cell style={{fontSize: 10.5}} dataKey="tempo_solicitacao_aprovacao">
          {(rowData) => (
            rowData?.tempo_solicitacao_aprovacao !== null ? (
              <span style={{fontSize: 10.5}}>{rowData?.tempo_solicitacao_aprovacao}</span>
            ):(
              <span style={{fontSize: 10.5}}>Indefinido</span>
            )
          )}
          </Cell>
        </Column>
     
        <Column width={100} align='center'>
          <HeaderCell>Materiais</HeaderCell>
          <Cell>
          {(rowData) => (
            <Button 
            appearance='link' 
            style={{fontSize: 11, margin: 0, padding: 0}}
            onClick={() => {
                if(rowData.material && rowData.material.length > 0) {
                    setOpen(true);
                    setMaterial(rowData?.material);
                }
            }}
            >
            { rowData.material && rowData.material.length > 0 ? 'Sim' : 'Não'}
        </Button >
          )}
          </Cell>
        </Column>
        

        <Column 
          width={150} 
          align="center" 
          flexGrow={2}
          fullText
          >
          <HeaderCell>Data da Solicitação</HeaderCell>
          <Cell style={{fontSize: 10.5}} dataKey="data_da_solicitacao" >
          {(rowData) => (
            <span>{moment(rowData?.data_da_solicitacao).format('llll')}</span>
          )}
          </Cell>
        </Column>


        <Column 
          width={80}
        >
          
          <HeaderCell>
            Status
          </HeaderCell>
         
          <Cell dataKey="status" >
          {(rowData) => (
            rowData?.status === 'aprovada' ? (
              <span style={{fontWeight: 600, color: 'green'}}>Aprovada</span>
            ):rowData?.status === 'pendente' ? (
              <span style={{fontWeight: 600, color: 'orange'}}>Pendente</span>
            ):rowData?.status === 'negada' ? (
              <span style={{fontWeight: 600, color: 'red'}}>Negada</span>
            ):rowData?.status === 'negado' ? (
              <span style={{fontWeight: 600, color: 'red'}}>Negada</span>
            ):rowData?.status === 'concluida' ? (
              <span style={{fontWeight: 600, color: 'blue'}}>Concluida</span>
            ):rowData?.status === 'cancelada' ? (
              <span style={{fontWeight: 600, color: 'gray'}}>Cancelada</span>
            ):rowData?.status === 'cancelar' ? (
              <span style={{fontWeight: 600, color: 'gray'}}>Cancelada</span>
            ):null
          )}
          
          </Cell>
        </Column>


        
          
        </>
      
      </Table>
      <div style={{ padding: 5, fontSize: 10,width: '100%' }}>
        <Pagination
          prev
          next
          boundaryLinks
          maxButtons={3}
          size="xs"
          layout={['total', '-','|', 'pager']}
          total={defaultdata.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  );
};

export default TodasSoliciacoes;

