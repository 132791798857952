import {  List,  Button} from 'semantic-ui-react'
import { useEffect, useState, } from 'react';
import 'semantic-ui-css/semantic.min.css'
import './styles.css'
import 'rsuite/dist/rsuite.min.css';
import LetteredAvatar from 'react-lettered-avatar';
import MenuIcon from '@rsuite/icons/Menu';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../api/api';
import axios from 'axios';
import { Drawer, IconButton, Placeholder, Dropdown, Input, Form, Uploader, Loader, InputNumber, Modal } from 'rsuite';
import * as XLSX from 'xlsx';
import introJs from 'intro.js';
import 'intro.js/introjs.css';



const renderIconButton = (props, ref) => {
    return (
      <IconButton {...props} size="sm" ref={ref}  icon={<MenuIcon/>} style={{backgroundColor: '#dfdede'}}/>
    );
};

function CadastroFuncionario(){
    const state = useSelector(state=>state.auth)
    const[funcionario, setFuncionario] = useState([]);
    const[loader, setLoader] = useState(false);
    const[loading, setLoading] = useState(false)
    const[listexclusion, setListExclusion] = useState([]);
    const[names, setNames] = useState({
        'nome': '',
        'matricula': '',
        'contrato': '',
    })
    const[contrato, setContrato] = useState([]);
    const[open, setOpen] = useState(false);
    const[openModal, setOpenModal] = useState(false)
    const[openEditor, setOpenEditor] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [files, setFiles] = useState([]);
    const[arquivo, setArquivo] = useState(null)
    




    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${BASE_URL}api/funcionarios/`, {
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
              },
            });
            setFuncionario(response.data?.results);
          } catch (error) {
            console.error('Error:', error);
          }
        };
        
        fetchData();
      }, []);

    

    useEffect(() => {
        if(searchQuery.length > 3){
            async function fetchEmployees() {
                try {
                const response = await axios.get(`${BASE_URL}api/funcionarios/`, {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${state.token}`
                    },
                    params: { q: searchQuery }
                });
                setFuncionario(response.data?.results);
                } catch (error) {
                
                }
            }
        
            fetchEmployees();
        }
    }, [searchQuery]);

    
    const HandleChange = (name, value) =>{
        setNames(prevValues => ({
            ...prevValues,
            [name]: value.toUpperCase()
        }));
    }
    




    const HandleSubmit = () =>{
        setLoader(true)
        axios({
            url: `${BASE_URL}api/funcionarios/`,
            method: 'POST',
            headers:{
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
            },
            data:names
        }).then(function (response){
            setLoader(false)
            NotificationManager.success('Funcionarios inserido', 'Sucesso', 1500);
            setNames(prevValues => ({
                ...prevValues,
                nome: '',
                matricula: '',
                contrato: ''
            }));
            
        }).catch(function (error){
            NotificationManager.error(`${error?.message}`, 'Warning', 1500);
        })
        
    }
    

    
    const readExcel = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
    
          reader.onload = (e) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
          };
    
          reader.onerror = (error) => {
            reject(error);
          };
        });
      };
    

    const handleUpload = (files) => {
        if(files.length > 0){
            setLoading(true)
            readExcel(files[0].blobFile)
            .then((data) => {
                setFiles(data)
                setLoading(false)
            })
            .catch((err) => console.error(err));
        }
        
    };



    const HandleUpdate = () =>{
        setLoading(true)
        axios({
            url: `${BASE_URL}api/funcionarios/update`,
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
              },
            data:{
                'data':files
            }
        }).then(function(response){
            console.log(response)
            setLoading(false)
        }).catch(function(error){
            console.log(error)
            setLoading(false)
        })
    }




    useEffect(() => {
        const startTour = () => {
            const intro = introJs();
          
            intro.setOptions({
              steps: [
                {
                  element: '.option-funcionarios',
                  intro: 'Foi adicionada a função de atualizar todo o efetivo, nessa função ele inativa os funcionarios que não estão no efetivo e insere os novos passado na planilha pelo usuario pelo usuario, Favor atenção, se a sua planilha estiver errada pode quebrar os dados',
                },
                
              ],
              doneLabel: 'Concluído'
            });
          
            intro.onexit(() => {
              localStorage.setItem('tour_funcionario', true);
            });
          
            intro.oncomplete(() => {
              // será executado quando o usuário concluir o tour
              localStorage.setItem('tour_funcionario', true);
            });
          
            // só iniciar o tour se a flag 'tour_solicitacoes_finished' não estiver definida
            if (!localStorage.getItem('tour_funcionario')) {
              intro.start();
            }
          };
          
          startTour();
          
      }, []);
    


    

    return(
        <>
        <Modal open={openModal} onClose={()=>setOpenModal(false)}>
                <Modal.Header>
                <Modal.Title>Importar funcionarios</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {loading && (
                    <Loader backdrop content="loading..." vertical />
                )}
                <Uploader
                 draggable
                 onChange={(e)=>handleUpload(e)}
                 autoUpload={false}
                 
                 >
                    <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span>Click ou arraste</span>
                    </div>
                </Uploader>
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={()=>setOpenModal(false)} style={{backgroundColor: 'red', color: '#fff'}} appearance="primary">
                    Cancelar
                </Button>

                <Button onClick={HandleUpdate} style={{backgroundColor: 'green', color: '#fff', margin: 5}} appearance="primary">
                    Enviar
                </Button>

                </Modal.Footer>
            </Modal>
        <div className='employes-container'>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', position: 'sticky', top: 0}}>
            <Dropdown renderToggle={renderIconButton} placement="rightStart" className='option-funcionarios'>
                <Dropdown.Item icon={<AddOutlineIcon/>} onClick={()=>setOpen(true)}>Novo</Dropdown.Item>
                <Dropdown.Menu title="Importar">
                <Dropdown.Item onClick={()=>setOpenModal(true)}>
                    Importar funcionarios
                </Dropdown.Item>
                </Dropdown.Menu>   
            </Dropdown>
            <Input placeholder="Search..." style={{maxWidth: 280, margin: 3}} onChange={(e)=>setSearchQuery(e)}/>
            </div>

            <div className='employes-child-one'>
            {Array.isArray(funcionario) && funcionario.length > 0 ? (
                <>
                {funcionario?.map((item, index)=>(
                <List key={index} divided verticalAlign='middle' translate="no">
                <List.Item>
                    <List.Content floated='right'>
                        <Button color='blue' icon='edit'/>
                        <Button color='red' icon='delete'/>
                    </List.Content>
                    <List.Content>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                            <div>
                                <LetteredAvatar  name={`${item?.nome?.slice(0, 1)}`}/>
                            </div>
                            <div style={{paddingLeft: '10px'}}>
                                <ul style={{listStyle:'none', marginLeft: '-40px', fontFamily: 'Open Sans, sans-serif', fontWeight: 600,}}>
                                    <li>
                                        {item?.nome?.slice(0,1).toUpperCase()}{item?.nome?.slice(1).toLowerCase()}
                                    </li>
                                    <li translate="no">
                                        {`matricula: ${item?.matricula}`}
                                    </li>
                                    <li style={{fontSize: 10}}>
                                        {item?.contrato?.nome}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </List.Content>
                </List.Item>
                </List>
                ))}
                </>
            ):(
                <>
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                    <Placeholder.Paragraph style={{ marginTop: 30 }} rows={4} graph="image" />
                </>
            )}
            </div>
        </div>
        <Drawer placement='right' open={open} onClose={() => setOpen(false)}>
            <NotificationContainer/>
            <Drawer.Header>
            <Drawer.Title>Funcionario</Drawer.Title>
            <Drawer.Actions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={HandleSubmit} color='blue' appearance="primary">
                Confirm
                </Button>
            </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body>
            {loader ?(
                <Loader backdrop content="loading..." vertical />
            ):(
                <Form fluid>
                <Form.Group controlId="name">
                <Form.ControlLabel>Nome completo</Form.ControlLabel>
                <Form.Control 
                    name="name"
                    value={names?.nome}
                    onChange={(e)=>HandleChange('nome', e)}
                />
                </Form.Group>



                <Form.Group controlId="contrato">
                <Form.ControlLabel>Centro de custo</Form.ControlLabel>
                <Form.Control 
                    name="contrato"
                    value={names?.contrato}
                    onChange={(e)=>HandleChange('contrato', e)}
                />
                </Form.Group>


                <Form.Group>
                <Form.ControlLabel>Matricula</Form.ControlLabel>
                <Form.Control 
                    name="matricula" 
                    accepter={InputNumber}
                    value={names?.matricula}
                    onChange={(e)=>HandleChange('matricula', e)} 
                />
                </Form.Group>
                
                </Form>
            )}    
            

            </Drawer.Body>
        </Drawer>

        </>
    )
}
export default CadastroFuncionario
