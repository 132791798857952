import { Table, Popover, Whisper, Dropdown, IconButton, Pagination, Button, Modal, FlexG } from 'rsuite';
import MoreIcon from '@rsuite/icons/legacy/More';
import { useState, useEffect, memo } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../api/api';
import img from '../../assets/cart.png';
import moment from 'moment';
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import TimeLineComponent from '../TimeLine'







const { Column, HeaderCell, Cell } = Table;
const rowKey = 'id';


const NameCell = ({ rowData, dataKey, ...props }) => {
  


  const speaker = (
    <Popover title={<span style={{fontSize: 11}}>Solicitante</span>}>
    
      <p>
        <b style={{fontSize: 10}}>Nome:</b> {rowData?.solicitante.first_name} {rowData?.solicitante.last_name}
      </p>
      <p style={{fontSize: 10}}>
        <b style={{fontSize: 10}}>Contrato:</b> {rowData?.solicitante?.contrato?.nome}
      </p>
    </Popover>
  );

  return (
    <Cell {...props}>
      <Whisper placement="top" speaker={speaker}>
        <a>{rowData[dataKey].first_name} {rowData[dataKey].last_name}</a>
      </Whisper>
    </Cell>
  );
};



const AproverCell = ({ rowData, dataKey, ...props }) => {
  


    const speaker = (
      <Popover title={<span style={{fontSize: 11}}>Aprovador</span>}>
        <p>
          <b style={{fontSize: 10}}>Nome:</b> {rowData?.aprovador?.first_name} {rowData?.aprovador?.last_name}
        </p>
        <p style={{fontSize: 10}}>
          <b style={{fontSize: 10}}>Contrato:</b> {rowData?.aprovador.contrato.nome}
        </p>
      </Popover>
  );

  return (
    <Cell {...props}>
      <Whisper placement="top" speaker={speaker}>
        <a>{rowData[dataKey].first_name} {rowData[dataKey].last_name}</a>
      </Whisper>
    </Cell>
  );
};


















const TableSo = ({values}) => {
  const state = useSelector(state=>state.auth)
  const [sortType, setSortType] = useState();
  const[defaultdata,setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const[open, setOpen] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth < 768;
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const[timeData, setTimeData] = useState(null)

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  const data = defaultdata.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  




  const updateFilter = (value) => {
    if (value != null) {
      if (value.length > 1) {
        const regex = new RegExp(`^${value}`, 'i'); // 'i' para torná-lo insensível a maiúsculas e minúsculas
        let filtered = filteredData.filter(item =>
          regex.test(item?.aprovador?.first_name) ||
          regex.test(item?.aprovador?.contrato?.nome) ||
          regex.test(item?.solicitante?.first_name) ||
          regex.test(item?.solicitante?.contrato?.nome) ||
          regex.test(item?.veiculo?.tag) ||
          regex.test(item?.veiculo?.placa) ||
          regex.test(item?.status)
        );
        setData(filtered);
      } else {
        setData(filteredData);
      }
    }
  };
  

  useEffect(() => {
    updateFilter(values);
  }, [values]);





  const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    <Cell {...props} style={{ padding: 5 }}>
      <IconButton
        appearance="subtle"
        onClick={() => {
          onChange(rowData);
        }}
        icon={
          expandedRowKeys.some(key => key === rowData[rowKey]) ? (
            <CollaspedOutlineIcon />
          ) : (
            <ExpandOutlineIcon />
          )
        }
      />
    </Cell>
  );
  
  
  const renderRowExpanded = rowData => {
    return (
      <div style={{ width: '100%',padding: 0, marginLeft: '19%', fontSize: 12}}>
          <p>Placa: {rowData?.veiculo_placa}</p>
          <p>Motivo: {rowData?.motivo}</p>
          <p>Destino: {rowData?.destino}</p>
          <p>Quilometragem percorrida: {rowData.km === null ? 'Indefinido': rowData.km}</p>
          <p>Tempo de conclusão: {rowData.aprovacao_duration}</p> 
      </div>
    );
  };





  
  
  

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}aprovacoes/solicitacao/search/`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${state.token}`
                }
            });
            console.log(response.data)
            setData(response.data);
            setFilteredData(response.data);     
        } catch (error) {
            
        }
    };

    fetchData();
}, []);



  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  
  const handleExpandRow = (rowData) => {
    const rowId = rowData[rowKey];
    const isRowExpanded = expandedRowKeys.includes(rowId);
    if (isRowExpanded) {
      setExpandedRowKeys(prevKeys => prevKeys.filter(key => key !== rowId));
    } else {
      setExpandedRowKeys(prevKeys => [...prevKeys, rowId]);
    }
  };




  const ModalComponent = () =>(
    <Modal open={open} onClose={()=>setOpen(false)}>
        <Modal.Header>
          <Modal.Title>Linha do Tempo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TimeLineComponent data={timeData}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>setOpen(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
    </Modal>
  )

  

  return (
    <div style={{overflowX: 'hidden', width: '100%'}}>
      <ModalComponent/>
      <Table 
      data={data} 
      height={500} 
      rowKey={rowKey}
      shouldUpdateScroll={false}
      fillHeight={false}
      sortType={sortType}
      expandedRowKeys={expandedRowKeys}
      rowExpandedHeight={130}
      renderRowExpanded={renderRowExpanded}
      style={{fontSize: 11, minHeight: '100%', overflowX: 'hidden', width: '100%'}}
      >
        {isMobile && (
          <Column width={60} align="center">
          <HeaderCell>#</HeaderCell>
          <ExpandCell dataKey="id" expandedRowKeys={expandedRowKeys} onChange={handleExpandRow}/>
          </Column>
        )}
       
        <Column width={!isMobile ? 80: 100} fullText>
          <HeaderCell>Solicitante</HeaderCell>
          <NameCell dataKey="solicitante" />
        </Column>


        <Column width={!isMobile ? 80: 100} fullText>
          <HeaderCell>Aprovador</HeaderCell>
          <AproverCell dataKey="aprovador" />
        </Column>

        <Column width={80}>
          
          <HeaderCell>
            Tag
          </HeaderCell>
          <Cell dataKey="veiculo">
          {(rowData,rowIndex ) => (
            <span style={{fontSize: 10.5}}>{rowData.veiculo.tag}</span>
          )}
          </Cell>
         
        </Column>

        
      

        {!isMobile && 
        <>


        <Column width={80}>
          
          <HeaderCell>
            Placa
          </HeaderCell>
          <Cell dataKey="veiculo">
          {(rowData,rowIndex ) => (
            <span style={{fontSize: 10.5}}>{rowData.veiculo.placa}</span>
          )}
          </Cell>
         
        </Column>

        <Column width={180} fullText flexGrow={2}>
          <HeaderCell>Motivo</HeaderCell>
          <Cell style={{fontSize: 10.5, textTransform: 'uppercase'}} dataKey="motivo" />
        </Column>


        <Column width={100} fullText flexGrow={2}>
          <HeaderCell>Destino</HeaderCell>
          <Cell style={{fontSize: 10.5, textTransform: 'uppercase'}} dataKey="destino" />
        </Column>


        <Column width={180} align="center">
          <HeaderCell>Tempo desde o último registro</HeaderCell>
          
          <Cell>
            {(rowData) => (
              rowData.tempo_em_percurso === null ? (
                <span style={{fontSize: 10.5}}>SAIDA NÃO REGISTRADA</span>
              ): rowData.tempo_em_percurso === 'complete' ? (
                <span style={{fontSize: 10.5}}>Concluido</span>
              ): (
                <span style={{fontSize: 10.5}}>{moment(rowData.tempo_em_percurso).fromNow()}</span>
              )
            )}
          </Cell>
        </Column>


        <Column width={180} fullText align="center">
          <HeaderCell>Quilometragem entre o ultimo registro</HeaderCell>
          <Cell>
            {(rowData) => {
              const hodometro = rowData?.hometro_da_solicitacao?.hodometro;
              if (hodometro !== null && hodometro !== undefined) {
                return <span style={{fontSize: 10.5, color: 'gray'}}>{hodometro}</span>;
              } else if (hodometro === null) {
                return <span>SEM REGISTRO</span>;
              } else {
                return <span style={{fontSize: 10.5, color: 'gray'}}>INDEFINIDO</span>;
              }
            }}
          </Cell>
        </Column>

        <Column width={150} fullText align="center" flexGrow={2}>
          <HeaderCell>Tempo entre solicitação e aprovação</HeaderCell>
          <Cell style={{fontSize: 10.5, textTransform: 'uppercase'}} dataKey="tempo_solicitacao_aprovacao" />
        </Column>
        

        <Column 
          width={80}
        >
          
          <HeaderCell>
            Status
          </HeaderCell>
         
          <Cell dataKey="status" >
          {(rowData) => (
            rowData?.status === 'aprovada' ? (
              <span style={{fontWeight: 600, color: 'green'}}>APROVADA</span>
            ):rowData?.status === 'pendente' ? (
              <span style={{fontWeight: 600, color: 'orange'}}>PENDENTE</span>
            ):rowData?.status === 'negada' ? (
              <span style={{fontWeight: 600, color: 'red'}}>NEGADA</span>
            ):rowData?.status === 'negado' ? (
              <span style={{fontWeight: 600, color: 'red'}}>NEGADA</span>
            ):rowData?.status === 'concluida' ? (
              <span style={{fontWeight: 600, color: 'blue'}}>CONCLUIDA</span>
            ):rowData?.status === 'cancelada' ? (
              <span style={{fontWeight: 600, color: 'gray'}}>CANCELADA</span>
            ):rowData?.status === 'cancelar' ? (
              <span style={{fontWeight: 600, color: 'gray'}}>CANCELADA</span>
            ):null
          )}
          
          </Cell>
        </Column>



        </>
        }
      </Table>
      <div style={{ padding: 5, fontSize: 10,width: '100%' }}>
        <Pagination
          prev
          next
          boundaryLinks
          maxButtons={3}
          size="xs"
          layout={['total', '-','|', 'pager']}
          total={defaultdata.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  );
};

export default memo(TableSo);

