import { Divider, Form, InputPicker, MaskedInput, Steps, Panel, Button, Input, Stack, Notification, IconButton} from 'rsuite';
import { Popup, List } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import './styles.css';
import _ from 'lodash';
import axios from 'axios';
import { BASE_URL } from '../../api/api';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import PlusIcon from '@rsuite/icons/Plus'
import TagIcon from '@rsuite/icons/Tag';
import MinusIcon from '@rsuite/icons/Minus';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import img from '../../assets/prot.png';
import introJs from 'intro.js';
import 'intro.js/introjs.css';


const options = [
    {
      name: 'Date with time',
      mask: [
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        ':',
        /\d/,
        /\d/,
        ' ',
        /a|p/,
        /m/
      ],
      placeholder: '16/05/2023 12:00 pm'
    }
];



const FormSocitiacao = () => {
    const state = useSelector(state=> state.auth)
    const [veiculos, setVeiculos] = useState([]);
    const navigate = useNavigate()
    const [option, setOption] = useState(options[0]);
    const[maname, setMName] = useState('')
    const[mquantidade, setMQuanti] = useState('')
    const[materialForm, setMaterialForm] = useState(false)
    const [step, setStep] = useState(0);
    const[error, setError] = useState(false)
    const[bntStyle, setBtnStyle] = useState({
        color: 'blue',
        text: 'Proximo'
    })

    const[funcionarios, setFuncionarios] = useState([]);
    const[loading, setLoading] = useState(false);
    const[usuario, setUsuario] = useState([]);
    const[filterUsuario, setFilterUsuario] = useState([]);
    const[inputEquipento, setInputEquipamento] = useState(null)
    const[formEquipemento, setFormEquipamento] = useState(false)
    const [formValues, setFormValues] = useState({
        veiculo: '',
        veiculo_id: '',
        placa: '',
        destino: '',
        funcionario: '',
        funcionario_id:'',
        centro_de_custo:'',
        matricula: '',
        data_saida: '',
        data_retorno: '',
        motivo: '',
        status: 'pendente',
        aprovador: '',
        aprovador_id: '',
        material: [],
        equipamento1: null,
        equipamento2: null
    });
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isMobile = windowWidth < 768;


  
    const LogOut = () =>{
        navigate("/")
    }


    useEffect(()=>{
        axios({
            url: `${BASE_URL}api/usuarios/`,
            method: 'GET',
            headers:{
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${state.token}`
            },
            
        }).then(function (response){
            setUsuario(response.data?.filter(item=>item?.aprovador === true))
            setFilterUsuario(response.data?.filter(item=>item?.aprovador === true))
        })
    },[])







    const handleTagChange = (value) => {
        const selectedVehicle = veiculos.find(veiculo => veiculo.tag === value);
        setError(false)
        
        if (selectedVehicle){
            const placaValue = selectedVehicle.placa ? selectedVehicle.placa : 'Sem placa';            
            setFormValues(prevValues => ({
                ...prevValues,
                placa: placaValue,
                veiculo: selectedVehicle.tag,
                veiculo_id: selectedVehicle.id
            }));
        }
    };



    const handleFunChange = (value) => {
        
        const selectedEmploye = funcionarios.find(funcionario => funcionario.nome === value);

        if (selectedEmploye) {
            
        
            setFormValues(prevValues => ({
                ...prevValues,
                funcionario_id: selectedEmploye?.id,
                funcionario: selectedEmploye?.nome,
                centro_de_custo: selectedEmploye?.contrato?.nome,
                matricula: selectedEmploye?.matricula,
            }));
        }
    };



    useEffect(() => {
        if(formValues.funcionario.length > 1){
            async function fetchEmployees() {
                try {
                const response = await axios.get(`${BASE_URL}api/funcionarios/`, {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${state.token}`
                    },
                    params: { q: formValues.funcionario }
                });
                setFuncionarios(response.data?.results);
                } catch (error) {
                    NotificationManager.error("Sessão Expirada, Por favor, faça o Login novamente.", 'Error', 500)
                    LogOut()

                }
            }
        
            fetchEmployees();
        }
    }, [formValues.funcionario]);




    useEffect(() => {
        if(formValues.veiculo.length > 1){
            async function fetchEmployees() {
                try {
                const response = await axios.get(`${BASE_URL}api/veiculos/`, {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${state.token}`
                    },
                    params: { q: formValues.veiculo }
                });
                setVeiculos(response.data?.results);
                } catch (error) {
                    NotificationManager.error("Sessão Expirada, Por favor, faça o Login novamente.", 'Error', 5000)
                    LogOut()
                }
            }
        
            fetchEmployees();
        }
    }, [formValues.veiculo]);



    const handleUserChange = (value) => {
        const selectedUser = usuario.find(usuario => usuario?.first_name === value);

        if (selectedUser) {
            setFormValues(prevValues => ({
                ...prevValues,
                aprovador: selectedUser?.first_name,
                aprovador_id: selectedUser.id
            }));
        }
    };



    const handleChangeAprovador = (text) => {
        if(text.length > 0){
            const data = usuario.filter(item => item.first_name.toUpperCase().includes(text.toUpperCase()));
            if(data.length > 0) {
                setFilterUsuario(data);
            }
        }
        
    }



    const addMaterial = (nome, quantidade) => {
        if(nome === '' || quantidade === ''){
            alert("Proibido campos em branco")
            return;
        }

        const newMaterial = { nome, quantidade };
        
        setFormValues(prevValues => ({
            ...prevValues,
            material: [...prevValues.material, newMaterial]
        }));
    };



    const removeMaterial = (index) => {
        setFormValues(prevValues => {
            const newMaterialArray = [...prevValues.material];
            
            newMaterialArray.splice(index, 1);
            
            return {
                ...prevValues,
                material: newMaterialArray
            };
        });
    };
    
    


    
 

    

    const HadleInputs = (event, value) =>{
        if(event === 'data_retorno' || event === 'data_saida'){
            if(value){
                const date = moment(value);
                const formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
                setFormValues({
                    ...formValues,
                    [event]: formattedDate 
                });
            }
        }else{
            setFormValues({
                ...formValues,
                [event]: value
            });
        }
        
    }




    const checkEmptyFields = () => {
        for (let key in formValues) {
          if (key !== 'material' && formValues[key] === '' && key !== 'data_retorno') {
            return true;
          }
        }
        return false;
      };


    const HandleSubmit = () =>{
        if (checkEmptyFields()) {
            alert('Por favor, preencha todos os campos necessários.');
            return;  
        }  
        setLoading(true)
        axios({
            url: `${BASE_URL}aprovacoes/solicitacao/`,
            method: 'POST',
            headers:{
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${state.token}`
            },
            data:formValues
        }).then(function (response){
            setLoading(false)
            setStep(step + 1)
            if(state.isAprover){
                HandleAprov(response.data)
                return;
            }
        }).catch(function(error){
            setLoading(false)
            NotificationManager.error(error?.response?.data?.error, 'Error', 1500)
            setError(true)
        })         
    }


    const HandleAprov = (value) => {
        axios({
          url: `${BASE_URL}aprovacoes/solicitacoes/aprovar/`,
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${state.token}`
          },
          data:{
            id: value,
            status: 'aprovada'
          }
        })
        .then((response) => {
         
        })
        .catch((error) => {
         
        });
      }





    const onChange = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
        if(nextStep === 3){
            setBtnStyle({...bntStyle, text: 'Concluir', color: 'green'})
        }else{
            setBtnStyle({...bntStyle, text: 'Proximo', color: 'blue'})
        }

    };





    const onNext = () => {
        setError(false)
        switch (step) {
            case 0:
                if(formValues.veiculo === '' || formValues.placa === '' || formValues.motivo === '' || formValues.destino === ''){
                    setError(true)
                    return;
                }else if(formValues.destino === state.location){
                    setError(true)
                    alert('O destino deve ser diferente da sua localização')
                    return;
                }
                break;
            case 1:
                if(formValues.funcionario === ''){
                    setError(true)
                    return;
                }
                break;
            
            case 2:
                if(formValues.aprovador === '' || formValues.data_saida === ''){
                    setError(true)
                    console.log(formValues.aprovador)
                    return;
                }
                
                const data_saida = moment(formValues.data_saida, 'YYYY-MM-DD HH:mm:ss')
                const data_retorno = formValues.data_retorno != "" ? moment(formValues.data_retorno, 'YYYY-MM-DD HH:mm:ss') : null;
                const agora = moment();
                if(data_retorno != null){
                    if(data_saida.isBefore(agora) || data_retorno.isBefore(data_saida)) {
                        setError(true)
                        return;
                    }
                }else if(data_retorno == null){
                    if(data_saida.isBefore(agora)) {
                        setError(true)
                        return;
                    }
                }
                
                break;

            default:
                break;
        }

        onChange(step + 1)
    };


    const onPrevious = () => {
        onChange(step - 1)
        setError(false)
        setLoading(false)
    };



    const renderMenu = menu => {
        if (veiculos.length === 0) {
          return (
            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
              <SpinnerIcon spin /> Loading...
            </p>
          );
        }
        return menu;
    };


    const renderFuncionario = menu => {
        if (funcionarios.length === 0) {
          return (
            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
              <SpinnerIcon spin /> Loading...
            </p>
          );
        }
        return menu;
    };



    const handleChangeMaterialAndEquip = (value) =>{
        if(value === 'equipamento'){
            setFormEquipamento(true)
            setMaterialForm(false)
        }else if(value === 'material'){
            setFormEquipamento(false)
            setMaterialForm(true)
        }
    }


    const HandleEquipement = () =>{
        if(formValues.equipamento1 === null){
            setFormValues({
                ...formValues,
                equipamento1: inputEquipento
            });
        }else{
            setFormValues({
                ...formValues,
                equipamento2: inputEquipento
            });
        }

        
    } 



    return(
        <div className='container-form'>
            <NotificationContainer/>
            <h2 className='title'>Solicitação de saida</h2>
            <Divider/>
            <Steps current={step} className='steps' currentStatus={error === true ? 'error':'process'}>
                <Steps.Item className='steps-chirden'/> 
                <Steps.Item className='steps-chirden' />
                <Steps.Item className='steps-chirden' />
                <Steps.Item className='steps-chirden' />
                <Steps.Item className='steps-chirden' />
            </Steps>

            <Panel style={{minHeight: '80vh', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            
            <Form fluid>
                {step === 0 && (
                   <>
                    <Form.Group controlId="tag">
                    <Form.ControlLabel>Tag</Form.ControlLabel>
                    <Form.Control 
                        name="Tag" 
                        className='input-form'
                        placeholder="Tag do veiculo"
                        accepter={InputPicker} 
                        style={{width: '100%'}} 
                        value={formValues?.veiculo}
                        onSearch={(e)=>setFormValues({...formValues, veiculo: e.toUpperCase()})}
                        data={veiculos.map(item => ({ label: item.tag, value: item.tag }))}
                        onChange={handleTagChange}
                        renderMenu={renderMenu}
                        />
                    </Form.Group>


                    <Form.Group controlId="placa">
                    <Form.ControlLabel>Placa</Form.ControlLabel>
                    <Form.Control
                        className='input-form'
                        value={formValues?.placa} 
                        name="placa" 
                        placeholder="Placa do veiculo"
                        onChange={(e)=>HadleInputs('placa', e)}
                        />
                    </Form.Group>


                    <Form.Group controlId="motivo">
                    <Form.ControlLabel>Motivo</Form.ControlLabel>
                    <Form.Control 
                        name="motivo" 
                        className='input-form'
                        value={formValues?.motivo}
                        onChange={(e)=>HadleInputs('motivo', e)}
                        placeholder="Motivo de saida" 
                    />
                    </Form.Group>



                    <Form.Group controlId="destino">
                    <Form.ControlLabel>Destino</Form.ControlLabel>
                    <Form.Control 
                        name="destino" 
                        className='input-form'
                        value={formValues?.destino}
                        onChange={(e)=>HadleInputs('destino', e)}
                        placeholder="Destino do veiculo" 
                    />
                    </Form.Group>


                   </> 
                )}


                {step === 1 && (
                   <>
                    
                    <Form.Group controlId="funcionario">
                    <Form.ControlLabel>Funcionario</Form.ControlLabel>
                    <Form.Control 
                        name="funcionario"
                        className='input-form' 
                        accepter={InputPicker} 
                        value={formValues?.funcionario}
                        data={funcionarios.map(item => ({ label: item.nome, value: item.nome }))} 
                        style={{width: '100%'}}
                        onChange={handleFunChange}
                        onSearch={(e)=>setFormValues({...formValues, funcionario: e})}
                        placeholder="Nome do funcionario"
                        renderMenu={renderFuncionario}
                    />

                    </Form.Group>


                    <Form.Group controlId="contrato">
                    <Form.ControlLabel>centro de custo</Form.ControlLabel>
                    <Form.Control 
                        className='input-form'
                        name="contrato" 
                        value={formValues?.centro_de_custo} 
                        onChange={(e)=>HadleInputs('centro_de_custo', e)}
                        placeholder="Centro de custo"
                    />
                    </Form.Group>


                    <Form.Group controlId="matricula">
                    <Form.ControlLabel>matricula</Form.ControlLabel>
                    <Form.Control 
                        className='input-form'
                        name="matricula" 
                        value={formValues?.matricula}
                        onChange={(e)=>HadleInputs('matricula', e)}
                        placeholder="Matricula" 
                    />
                    </Form.Group>

                   </> 
                )}

                {step === 2 &&(
                    <>


                    <Form.Group controlId="aprovador">
                    <Form.ControlLabel>Aprovador</Form.ControlLabel>
                    <Form.Control 
                        name="aprovador" 
                        className='input-form'
                        accepter={InputPicker}
                        value={formValues?.aprovador}
                        data={usuario.map(item => ({ label: `${item.first_name} ${item.last_name}`, value: item.first_name}))} 
                        style={{width: '100%'}}
                        onChange={handleUserChange}
                        onSearch={(e)=>handleChangeAprovador(e)}
                        placeholder="Nome do Aprovador"
                    />
                    </Form.Group>

                    

                    <Divider/>

                    <Form.Group controlId="data_de_saida">
                    <Form.ControlLabel>Data de saida</Form.ControlLabel>
                    <Form.Control 
                        name="Data de saida" 
                        className='input-form'
                        accepter={Input} 
                        type='datetime-local'
                        style={{width: '100%'}} 
                        value={formValues?.data_saida}
                        onChange={(e)=>HadleInputs('data_saida', e)}
                    />

                    </Form.Group>



                    

                    <Form.Group  controlId="data_de_retorno">
                    <Form.ControlLabel>Data de retorno</Form.ControlLabel>
                    <Form.Control
                        name="data_de_retorno"  
                        className='input-form'
                        accepter={Input}
                        type='datetime-local'
                        style={{width: '100%'}}
                        value={formValues?.data_retorno}
                        onChange={(e)=>HadleInputs('data_retorno', e)}
                    />

                    </Form.Group>
                    </>
                )}

                {step === 3 &&(
                    <div style={{width: '100%', display: 'flex', flexDirection:'column', height: '100%', alignItems: 'center', justifyContent: 'center', overflowX: 'auto'}}>      
                    <Fab
                        mainButtonStyles={{ backgroundColor: '#00aeff', bottom: 10,}}
                        icon={<PlusIcon/>}
                        event="click"
                    > 
                   
                    <Action
                        text="ADD Material"
                        className='material'
                        onClick={() => handleChangeMaterialAndEquip('material')}
                        style={{backgroundColor: '#e7e6e600'}}
                    ><Icon name='box' circular color='blue' size='large' style={{marginRight: -0.5}}/></Action>

                    <Action
                        text="ADD Equipamento"
                        className='equipamento'
                        onClick={() => handleChangeMaterialAndEquip('equipamento')}
                        style={{backgroundColor: '#e7e6e600'}}
                    ><Icon name='truck' circular color='blue' size='large' style={{marginRight: -0.5}}/></Action>
                        
                    </Fab>
                    
                    
                    {materialForm  ? (
                        <div className='container-material'>
                        {!isMobile && (
                            <>
                            <div className='material-top'>
                                <div>
                                    <img src={img} alt='image_logo' style={{height: '100%', width:'100%'}}/>
                                </div>
                                <div>
                                    <span style={{fontWeight: 600}}>SOLICITAÇÃO DE SAIDA DE MATERIAIS</span>
                                </div>
                            </div>
                            <div className='material-body'>
                                <div>
                                    <span style={{fontWeight: 600, fontSize: 13, margin: 5}}>
                                        Data: <span style={{fontWeight: 'normal'}}>{moment().format('llll')}</span>
                                    </span>
                                </div>
                                <div>
                                    <span style={{fontWeight: 600, fontSize: 13, margin:5}}>
                                        Solicitante: <span style={{fontWeight: 'normal'}}>{state.firstName} {state.lastName}</span>
                                    </span>
                                </div>
                
                                <div>
                                    <span style={{fontWeight: 600, fontSize: 13, margin:5}}>
                                        Contrato: <span style={{fontWeight: 'normal'}}>{state.Contrato}</span>
                                    </span>
                                </div>
                            </div>
                            </>
                        )}
                        <table className='material-list'>
                        <thead>
                            <tr>
                                <th>Descrição</th>
                                <th>Quantidade</th>
                                <th>...</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {formValues.material.length > 0 && (
                                formValues.material.map((item, index)=>(
                                    <tr style={{fontSize: 13}}>
                                        <td style={{fontSize: 13, height: 'auto', whiteSpace: 'normal'}}>{item.nome}</td>
                                        <td style={{fontSize: 13}}>
                                            {item.quantidade} 
                                        </td>
                                        <td style={{padding: 0}}>
                                        <IconButton icon={<MinusIcon/>} style={{backgroundColor: '#f96262', color: '#fff', width: '100%', borderRadius: 0}} onClick={() => removeMaterial(index)}/>
                                        </td>
                                    </tr> 
                                ))
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="3">Aprovador: {formValues.aprovador}</td>
                            </tr>
                            <tr>
                        <td style={{margin: 0, padding: 0}}>
                          <Input
                            style={{ width: '100%', minHeight: '100%', borderRadius: 0}}
                            type="text"
                            placeholder="Adicionar novo item..."
                            onChange={(e)=>setMName(e.toUpperCase())}
                          />
                        </td>
                        <td style={{margin: 0, padding: 0}}>
                          <Input
                            style={{ width: '100%', minHeight: '100%', borderRadius: 0}}
                            type="number"
                            placeholder="Quantidade..."
                            onChange={(e)=>setMQuanti(e)}
                          />

        
                        </td>
                        <td style={{margin: 0, padding: 0}}>
                        <IconButton icon={<PlusIcon/>} appearance="primary" style={{backgroundColor: 'green', width: '100%', borderRadius: 0}} onClick={() => addMaterial(maname, mquantidade)}/>
                        </td>
                      </tr>
                        </tfoot>
                        </table>
            
                        
            
                        </div>
                    
                    ): formEquipemento ? (
                        <div className='container-material'>
                        {!isMobile && (
                            <>
                            <div className='material-top'>
                                <div>
                                    <img src={img} alt='image_logo' style={{height: '100%', width:'100%'}}/>
                                </div>
                                <div>
                                    <span style={{fontWeight: 600}}>SOLICITAÇÃO DE SAIDA DE EQUIPAMENTOS</span>
                                </div>
                            </div>
                            <div className='material-body'>
                                <div>
                                    <span style={{fontWeight: 600, fontSize: 13, margin: 5}}>
                                        Data: <span style={{fontWeight: 'normal'}}>{moment().format('llll')}</span>
                                    </span>
                                </div>
                                <div>
                                    <span style={{fontWeight: 600, fontSize: 13, margin:5}}>
                                        Solicitante: <span style={{fontWeight: 'normal'}}>{state.firstName} {state.lastName}</span>
                                    </span>
                                </div>
                
                                <div>
                                    <span style={{fontWeight: 600, fontSize: 13, margin:5}}>
                                        Contrato: <span style={{fontWeight: 'normal'}}>{state.Contrato}</span>
                                    </span>
                                </div>
                            </div>
                            </>
                        )}
                        <table className='material-list'>
                        <thead>
                            <tr>
                                <th>Equipamento</th>
                                <th>...</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {formValues.equipamento1 != null &&(
                                <tr style={{fontSize: 13}}>
                                <td style={{fontSize: 13, height: 'auto', whiteSpace: 'normal'}}>{formValues.equipamento1}</td>
                                <td style={{padding: 0}}>
                                <IconButton icon={<MinusIcon/>} style={{backgroundColor: '#f96262', color: '#fff', width: '100%', borderRadius: 0}} onClick={()=>setFormValues({...formValues, equipamento1: null})}/>
                                </td>
                                </tr>
                            )}

                            {formValues.equipamento2 != null &&(
                                <tr style={{fontSize: 13}}>
                                <td style={{fontSize: 13, height: 'auto', whiteSpace: 'normal'}}>{formValues.equipamento2}</td>
                                <td style={{padding: 0}}>
                                <IconButton icon={<MinusIcon/>} style={{backgroundColor: '#f96262', color: '#fff', width: '100%', borderRadius: 0 }} onClick={()=>setFormValues({...formValues, equipamento2: null})}/>
                                </td>
                                </tr>
                            )}
                            
                           
                        </tbody>
                        <tfoot>
        
                        <tr>
                        <td style={{margin: 0, padding: 0}}>
                        {formValues.equipamento1 != null && formValues.equipamento2 != null ? (
                            <></>
                        ):(
                            <Input
                            style={{ width: '100%', minHeight: '100%', borderRadius: 0}}
                            type="text"
                            placeholder="Adicionar Equipamento"
                            onChange={(e)=>setInputEquipamento(e.toUpperCase())}
                          />
                        )}
                          
                        </td>
                        <td style={{margin: 0, padding: 0}}>
                            {formValues.equipamento1 != null && formValues.equipamento2 != null ? (
                                <>
                                </>
                            ):(
                                <IconButton icon={<PlusIcon/>} appearance="primary" style={{backgroundColor: 'green', width: '100%', borderRadius: 0}} onClick={HandleEquipement}/>
                            )}
                        
                        </td>
                        </tr>
                        </tfoot>
                        </table>    
            
                        </div>
                    ):null}
                    </div>
                )}
                {step === 4 &&(
                    <>
                    <div style={{width: '100%', margin: 10, padding: 10}}>
                    <div style={{ margin: '40px 0' }}>
                        <Stack spacing={10}>
                        <CheckRoundIcon style={{ fontSize: 50 }} color="#4caf50" />
                        <div>
                            <h5 className='title-action'>Solicitação concluida!</h5>
                            <p className="text-muted">{state.isAprover ? "O Veiculo esta liberado": `A sua solicitação ja foi informada para o ${formValues.aprovador}`}</p>
                        </div>
                        </Stack>
                    </div>
                    <div className='container-text'>
                    <p>
                        {!state.isAprover ? "Não deixe de conferir com o aprovador se a solicitação foi recebida, isso acelera o processo.": null}
                    </p>
                    <p> - pMonitor</p>
                    </div>
                    
                    </div>
                    

                    </> 
                    
                )}

            </Form>
            

            <div style={{marginTop: step === 3 ? '20%': '10%', marginBottom: '20%'}}>
            <Button onClick={onPrevious} style={{margin: 5}} color="red" appearance="primary" disabled={step === 0}>
            Retornar
            </Button>
            <Button onClick={step === 3 ? HandleSubmit : onNext} color={bntStyle.color} appearance="primary" disabled={step === 4} loading={loading}>
            {bntStyle.text}
            </Button>
            </div>
            </Panel>
        </div>

        
    )
}

export default FormSocitiacao;



