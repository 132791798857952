import { useState, useEffect, memo, useRef } from 'react';
import './styles.css';
import Chart from 'react-apexcharts';


const BarChartItem = ({data, childChange, rvalue}) =>{
  const chartRef = useRef(null);

  


  useEffect(() => {
    if (rvalue !== null && chartRef.current) {
      const index = data.findIndex(item => item?.solicitante__contrato__nome === rvalue);
      if (index !== -1) {
        setTimeout(() => {
          chartRef.current.chart.toggleDataPointSelection(0, index);
        }, 10);
      }
    }
  }, [rvalue]);
  

    
  const options = {
    chart: {
        type: 'bar',
        fontFamily: 'inherit', 
        parentHeightOffset: 0,
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: (event, chartContext, { dataPointIndex }) => {
            const selectedValue = data[dataPointIndex];
            childChange(selectedValue?.solicitante__contrato__nome);
          }
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 900,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 450  
          }
        },
        stacked: true   
    },
    
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    grid: {
      padding: {
        top: -20,
        right: 0,
        left: -4,
        bottom: -15
      },
      
      strokeDashArray: 4,
    },
    xaxis: {
      categories: data?.map(item => item?.solicitante__contrato__nome?.substring(0, 3)),
      tooltip: {
            enabled: true,
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                return data[dataPointIndex]?.solicitante__contrato__nome;
        }
      },
      axisBorder: {
        show: false
      },
    },
    yaxis: {
      labels: {
        padding: 4
      }
    },
    colors: ['#206bc4', '#79a6dc', '#bfe399'],
    legend: {
      show: false
    }
  }

  const series = [{
    name: 'Solicitações',
    data: data.map(item => item?.total)
  }]



  return (
    <div>
      <Chart ref={chartRef} options={options} series={series} type="bar" height={280}/>
    </div>
  )
    
}

export default memo(BarChartItem)